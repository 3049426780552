import  React, { useState, useEffect} from "react";
import { IoIosArrowDown, IoIosCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import * as Checkbox from "@radix-ui/react-checkbox";
import { SERVER_URL } from "../../../App";
import LoadingElement from "../loadingElement";


interface GoogleWorkspaceProps {
  userEmail: string;
  userDetails?: any;
  setAccountConfiguration: any;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;

}


function GoogleWorkspace(props: GoogleWorkspaceProps) {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getServiceData();
  }, []);

  useEffect(() => {
    props.setAccountConfiguration({firstName: firstName, surname: surname, email: email});
  }, [props.userEmail]);
  
   // Collects user's and subordinates data froms server
   async function getServiceData() {
    // setLoading(true);
    props.setLoaded && props.setLoaded(false);

    const url = SERVER_URL + "/dashboard/getManagedServiceDetails";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        accountUser: props.userEmail,
        accountSlug: "googleworkspace",
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();
      console.log(data);
      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=getServiceData&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        console.log(data["msg"]);
      } else {
        const userAccountDetails = data["data"]["userAccountDetails"];

        // If the user has an account already, prefill it's details
        if (userAccountDetails !== null) {
          setFirstName(userAccountDetails["accountConfiguration"]["firstName"]);
          setSurname(userAccountDetails["accountConfiguration"]["surname"]);
          setEmail(userAccountDetails["accountConfiguration"]["email"]);
        } else {
          setFirstName(props.userDetails.firstName);
          setSurname(props.userDetails.surname);
          setEmail(props.userDetails.email);
        }

        setLoading(false);
        props.setLoaded && props.setLoaded(true);
        return true;
      }
    } catch (error) {
      console.log(error);
      // Redirect to login
      navigate("/login?error=true&responseStatus=408&trigger=getServiceData");
    }
  }

  if (loading) {
    return (
      <div style={{ height: 175}}>
        <LoadingElement />
      </div>
    )
  } else {
    return (
      <div className="form-cont fill left">
        <div className="green-text-cont left">
          <h4>Basic Account Details</h4>
            <div>
              <h5>First Name: {firstName}</h5>
              <h5>Surname: {surname}</h5>
              <h5>Email: {email}</h5>
              <h5>Password: Randomly Generated</h5>
            </div>
        </div>
        <div className="modal-section">
          <h4>Included Services</h4>
          <div className="app-icon-button-grid-cont">
            <img src={`/assets/app-icons/gmail.png`} className="app-icon" />
            <img src={`/assets/app-icons/googledrive.png`} className="app-icon" />
            <img src={`/assets/app-icons/googlemeet.png`} className="app-icon" />
            <img src={`/assets/app-icons/googlecalender.png`} className="app-icon" />
            <img src={`/assets/app-icons/googledocs.png`} className="app-icon" />
            <img src={`/assets/app-icons/googlesheets.png`} className="app-icon" />
            <img src={`/assets/app-icons/googleslides.png`} className="app-icon" />
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleWorkspace;
