import React, { useEffect, useState } from "react";
import Navbar from "../components/atoms/navbar";
import LoginForm from "../components/molecules/loginForm";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SERVER_URL } from "../App";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/atoms/loadingScreen";

function ExtensionRedirect() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  function verifyToken(token: string) {
    console.log("verifying token", token);
    return fetch(SERVER_URL + "/dashboard/VerifyExtensionRedirect", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            setErrorMessage(data.msg);
            throw new Error(data.msg);
          });
        }
      })
      .then((data) => {
        localStorage.setItem("companyID", data["companyID"]);
        localStorage.setItem("token", token);
        localStorage.setItem("companyName", data["companyName"]);
        localStorage.setItem("email", data["userEmail"]);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.msg);
        navigate("/login");
      });
  }

  let redirectTimer: NodeJS.Timeout;
  useEffect(() => {
    redirectTimer = setTimeout(() => {
      navigate("/login");
    }, 4000);
    return () => clearTimeout(redirectTimer);
  }, []);

  window.addEventListener("atticTokenInStorage", async () => {
    console.log("Token in local storage!");
    clearTimeout(redirectTimer);
    const token = window.localStorage.getItem("token");
    if (token) {
      verifyToken(token);
    }
  });

  return (
    <div className="page-container">
      <Navbar authedOnly={false} logoOnly={true} />
      <LoadingScreen
        messages={[
          "Checking your tokens...",
          "Opening the Attic...",
          "Climbing the ladder...",
          "Where's the light switch?",
          "Just a moment...",
        ]}
      />
    </div>
  );
}

export default ExtensionRedirect;
