import React, { useState, useEffect } from "react";
import Navbar from "../../components/atoms/navbar";
import SetPasswordForm from "../../components/molecules/passwords/setPasswordForm";
import { useSearchParams } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { IoArrowForwardOutline } from "react-icons/io5";
import GetGoogleAuth from "../../components/atoms/getGoogleAuth";

function SetPassword(props: { type: "subordinate" | "superadmin" }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);

  const token = searchParams.get("passwordToken");
  // const encryptionKey = searchParams.get("encryptionKey");

  function submitPassword() {
    setShowPasswordInput(true);
    setPasswordCheck(true);
    setTimeout(() => {
      setPasswordCheck(false);
      setPasswordConfirm(true);
    }, 4000);
  }

  return (
    <div className="page-container">
      <Navbar authedOnly={false} />
      <div hidden={showPasswordInput}>
        <div className="full-page-element row" hidden={true}>
          <div className="half-column">
            <div className="center-page-element content-container">
              <h2>Let's make your password super secure.</h2>
              <h2 className="title-txt">F*aint.Mix_ture.Garl%ic</h2>
              <h4>
                Tip: A good password needs to be long, varied, and random. Try three random words, with symbols in the
                middle of each.
              </h4>
            </div>
          </div>
          <div className="half-column">
            <div className="center-page-element">
              <SetPasswordForm token={token} onSubmit={submitPassword} type={props.type} />
            </div>
          </div>
        </div>
      </div>

      <div hidden={!passwordCheck}>
        <div className="center-page-element full-page-message">
          <h2>Checking to see if your password is secure...</h2>

          <MoonLoader color="#20513B" cssOverride={{}} loading size={50} speedMultiplier={0.75} />
        </div>
      </div>

      <div hidden={!passwordConfirm}>
        <div className="center-page-element full-page-message">
          <h2>You nailed it. Are you ready to get started?</h2>
          <p>Your password is secure. Your account has been updated, and you are now able to log in again. </p>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
