/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../atoms/loadingButton";
import "../../App.css";
import { SERVER_URL } from "../../App";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";


interface AddUnmanagedIndividualAccountProps {
  user: any;
  updateUserData: () => void;
}

function AddUnmanagedIndividualAccount(props: AddUnmanagedIndividualAccountProps) {
  const [accountUsername, setAccountUsername] = useState(props.user.email);
  const [accountHostname, setAccountHostname] = useState("");
  const [accountPassword, setAccountPassword] = useState("");
  const [accountName, setAccountName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const navigate = useNavigate();

  async function submitForm() {
    const url = SERVER_URL + "/dashboard/StoreUnmanagedIndividualAccount";

    const token = localStorage.getItem("token");

    // All fields must have data
    if (accountUsername === "" || accountPassword === "" || accountName === "" || accountHostname === "") {
      setErrorMessage("No fields can be blank");
      return Promise.reject(new Error("No fields can be blank"));
    }

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        accountName: accountName,
        accountHostname: accountHostname,
        accountUsername: accountUsername,
        accountPassword: accountPassword,
        accountSlug: accountHostname,
        atticManaged: false,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([
        responsePromise,
        timeoutPromise,
      ]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=addIndividualAccountsForm&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));

      } else if (!data["success"]) {
        setErrorMessage(data.msg);
        return Promise.reject(new Error(data.msg));

      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=addIndividualAccountsForm");
    }
  }

  return (
    <>
      <div className="form-cont fill" style={{ marginBottom: 16 }}>
        <input
          type="text"
          autoComplete="off"
          placeholder="Account Name"
          onChange={(event) => setAccountName(event.target.value)}
          onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-acc-btn")?.click()}
          value={accountName}
        />
        <input
          type="text"
          autoComplete="off"
          autoCapitalize="off"
          placeholder="Account URL"
          onChange={(event) => setAccountHostname(event.target.value)}
          onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-acc-btn")?.click()}
          value={accountHostname}
        />
        <input
          type="text"
          autoComplete="off"
          autoCapitalize="off"
          placeholder="Account Username"
          onChange={(event) => setAccountUsername(event.target.value)}
          onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-acc-btn")?.click()}
          value={accountUsername}
        />
        <div style={{ position: "relative" }}>
          <input
            type={passwordHidden ? "password" : "text"}
            placeholder="Password"
            autoComplete="off"
            onChange={(event) => setAccountPassword(event.target.value)}
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-acc-btn")?.click()}
            value={accountPassword}
          />
          {passwordHidden ? (
            <HiOutlineEye onClick={() => setPasswordHidden(false)} className="password-icon" size={20} />
          ) : (
            <HiOutlineEyeSlash onClick={() => setPasswordHidden(true)} className="password-icon" size={20} />
          )}
        </div>
      </div>
      <LoadingButton id="add-acc-btn" text={"Add Account"} singleUse={true} onClick={submitForm} />
      {errorMessage ? <p className="error-txt" style={{marginTop: 16}}>{errorMessage}</p> : null}
    </>
  );
}

export default AddUnmanagedIndividualAccount;
