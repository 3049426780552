import React, { useState, useEffect } from "react";
import Navbar from "../../components/atoms/navbar";
import SetPasswordForm from "../../components/molecules/passwords/setPasswordForm";
import { useSearchParams } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { IoArrowForwardOutline } from "react-icons/io5";
import GetGoogleAuth from "../../components/atoms/getGoogleAuth";

function RefreshGoogleAuth() {
  const token = localStorage.getItem("token");
  return (
    <div className="page-container">
      <Navbar authedOnly={false} />

      <div>
        <div className="center-page-element full-page-message">
          <h2>Enable us to get email codes.</h2>
          <p>
            We need access to your work email so we dip in and grab things like 6-digit log in code. Click the button to
            authorise us to do this.
          </p>
          <GetGoogleAuth token={token as string} />
        </div>
      </div>
    </div>
  );
}

export default RefreshGoogleAuth;
