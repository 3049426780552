import React, { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";

export default function LoadingScreen(props: { messages?: string[] }) {
  const [message, setMessage] = useState("Loading...");
  useEffect(() => {
    let counter = 0;
    const messageTimer = setInterval(() => {
      if (props.messages) {
        if (counter < props.messages.length - 1) {
          counter += 1;
        } else {
          counter = 0;
        }
        setMessage(props.messages[counter]);
      }
    }, 4000);

    return () => clearInterval(messageTimer);
  }, []);

  return (
    <div className="loading-page-container">
      <MoonLoader
        color="#20513b"
        cssOverride={{ display: "flex", marginLeft: "auto", marginRight: "auto" }}
        loading
        size={40}
        speedMultiplier={0.75}
      />
      <h3>{message}</h3>
    </div>
  );
}
