import React, { useState } from "react";
import Navbar from "../../components/atoms/navbar";
import { useSearchParams } from "react-router-dom";
import RequestPasswordResetForm from "../../components/molecules/passwords/requestPasswordResetForm";
import LoadingButton from "../../components/atoms/loadingButton";
import { SERVER_URL } from "../../App";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";

function SubordinatePasswordReset() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationVisibility, setConfirmationVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [passwordHidden, setPasswordHidden] = useState(true);

  const token = searchParams.get("resetToken");
  const subordinateName = searchParams.get("subordinateName");

  const url = SERVER_URL + "/atticaccounts/ManagerApprovePasswordReset";

  function submitForm() {
    return fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((res) => {
        const data: any = res.json();
        console.log(data);
        return data;
      })
      .then((data) => {
        if (data["success"]) {
          setConfirmationVisibility(true);
          return Promise.resolve();
        } else {
          setErrorMessage(data["msg"]);
          return Promise.reject(new Error(data["msg"]));
        }
      });
  }

  return (
    <div className="page-container">
      <Navbar authedOnly={false} />
      <div className="center-page-element">
        {!confirmationVisibility ? (
          <div hidden={confirmationVisibility}>
            <h2>{subordinateName} wants to reset their password.</h2>
            <p>
              Hit approve to allow your team member to reset their password. If this activity is suspicious, contact us,
              at support@useattic.com.
            </p>

            <input
              type="text"
              autoComplete="off"
              placeholder="Email"
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("reset-btn-2")?.click()}
              autoCapitalize="off"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
            <div style={{ position: "relative" }}>
              <input
                type={passwordHidden ? "password" : "text"}
                autoComplete="off"
                placeholder="Password"
                onKeyDown={(event) => event.key === "Enter" && document.getElementById("reset-btn-2")?.click()}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
              />
              {passwordHidden ? (
                <HiOutlineEye onClick={() => setPasswordHidden(false)} className="password-icon" size={20} />
              ) : (
                <HiOutlineEyeSlash onClick={() => setPasswordHidden(true)} className="password-icon" size={20} />
              )}
            </div>

            <button
              className="submit-button"
              onClick={() => {
                submitForm();
              }}
            >
              <LoadingButton id="reset-btn-2" text="Reset Password" onClick={submitForm} />
              <h3 className="button-txt">Approve</h3>
            </button>

            <div className="form-error-cont" style={{ padding: 0 }}>
              <p className="error-txt">{errorMessage}</p>
            </div>
          </div>
        ) : (
          <div className="center-page-element full-page-message">
            <h2>We've got it from here.</h2>
            <p>
              Thank you for approving. We have sent {subordinateName} an email to reset their password. It might be
              worth giving them a poke to let them know.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubordinatePasswordReset;
