import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Command } from "cmdk";
import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoCard, IoTime } from "react-icons/io5";

interface SubordinatesModuleProps {
  users: any;
  setSelectedUser: (user: string) => void;
}

function SubordinatesModule(props: SubordinatesModuleProps) {
  const [userCards, setUserCards] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    renderUsers();
  }, [props.users]);

  function getRelativeTime(timestamp: any) {
    if (timestamp === "Never") {
      return "Setup Incomplete";
    }
    const millisecondsAgo = Date.now() - timestamp;
    const secondsAgo = Math.floor(millisecondsAgo / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    const weeksAgo = Math.floor(daysAgo / 7);
    const monthsAgo = Math.floor(daysAgo / 30);

    if (hoursAgo < 24) {
      return `Active Today`;
    } else if (daysAgo < 7) {
      switch (daysAgo) {
        case 1:
          return "Active Yesterday";
        default:
          return `Active ${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
      }
    } else if (weeksAgo < 4) {
      return `Active ${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
    } else {
      return `Active ${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
    }
  }

  async function renderUsers() {
    const supportedServicesString: any = await localStorage.getItem("supportedServicesString");
    const supportedServices = await JSON.parse(supportedServicesString);

    const userCards = Object.entries(props.users).map(([email, user]: [string, any]) => {
      const { firstName, surname, individualLogins, lastLogin } = user;
      const fullName = `${firstName} ${surname}`;
      let monthlyBill = 9.99;

      for (const service of Object.entries(individualLogins)) {
        console.log(service);
        const serviceConfig: any = service[1];
        const serviceDetails = supportedServices[serviceConfig.slug];

        if (serviceDetails) {
          monthlyBill += serviceDetails.plans[serviceConfig.plan];
        }
      }

      return (
        <Command.Item key={`user-${email}`} value={fullName}>
          <div className="user-card" onClick={() => props.setSelectedUser(email)}>
            <div className="card-header">
              <div className="user-profile-placeholder">
                {firstName[0]}
                {surname[0]}
              </div>
              <div>
                <h4>{surname},</h4>
                <h4>{firstName}</h4>
              </div>
            </div>
            <div>
              <div className="icon-text-cont">
                <IoCard />
                <p>£{monthlyBill.toFixed(2)}/month</p>
              </div>
              <div className="icon-text-cont">
                <IoTime />
                <p>{getRelativeTime(lastLogin)}</p>
              </div>
            </div>
          </div>
        </Command.Item>
      );
    });

    setUserCards(userCards);
  }

  return (
    <div className="twothird-full-height-module">
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">Team Members</h3>
          <p>Click to view a team member’s access and details.</p>
        </div>
        <div className="button-cont row">
          <Link to="/addTeamMember">
            <button className="button pill secondary right">
              <h5>Add New</h5>
            </button>
          </Link>
        </div>
      </div>

      <Command className="apps-cmdk-container">
        <div style={{ position: "relative", marginBottom: 24 }}>
          <Command.Input placeholder="Search your team" className="with-icon" />
          <MdSearch size={20} className="input-search-icon" />
        </div>

        <Command.List className="user-card-container">
          {isLoading && (
            <Command.Loading>
              <div>Hang on…</div>
            </Command.Loading>
          )}

          <Command.Empty>
            <div>No results found.</div>
          </Command.Empty>
          {userCards}
        </Command.List>
      </Command>
    </div>
  );
}

export default SubordinatesModule;
