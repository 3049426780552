/* eslint-disable guard-for-in */
import React, { useEffect, useState } from "react";
import Navbar from "../components/atoms/navbar";
import UserDetailsModule from "../components/organisms/userDetailsModule";
import PersonalAccountsModule from "../components/organisms/personalAccountsModule";
import SharedAccountsModule from "../components/organisms/sharedAccountsModule";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "../components/atoms/loadingScreen";
import { SERVER_URL } from "../App";

interface userDetailProps {
  currentUser: boolean;
  users: any;
  selectedUser: string;
  managedServices: object;
  updateUserData: () => void;  
  returnFunction: () => void;
}

function UserDetail(props: userDetailProps) {
  const [personalAccounts, setPersonalAccounts] = useState<any>();

  async function getUserAccounts() {
    
    setPersonalAccounts(
      <PersonalAccountsModule
        updateUserData={props.updateUserData}
        user={props.users[props.selectedUser]}
        currentUser={props.currentUser}
        managedServices={props.managedServices}
      />
    );
  }

  useEffect(() => {
    getUserAccounts();
  }, [props.users]);

  return (
    <div className="page-container">
      <Navbar authedOnly={false} />
      <div className="full-page-element">
        <h2 onClick={() => props.returnFunction()} style={{ marginBottom: 8, cursor: "pointer" }}>{" < "} Back</h2>
        <div className="module-container">
          <UserDetailsModule manager={true} updateUserData={props.updateUserData} user={props.users[props.selectedUser]} currentUser={props.currentUser} />
          <div className="threefifth-column">
            {personalAccounts}
            <SharedAccountsModule
              updateUserData={props.updateUserData}
              user={props.users[props.selectedUser]}
              allUsers={props.users}
              currentUser={props.currentUser}
            />
          </div>
        </div>
      </div>
    </div>
    );
  }

export default UserDetail;
