import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import AddTeamMember from "./pages/addTeamMember";
import RequestPasswordReset from "./pages/passwords/requestPasswordReset";
import SubordinatePasswordReset from "./pages/passwords/subordinatePasswordReset";
import ResetPassword from "./pages/passwords/resetPassword";
import ResetAdminPassword from "./pages/passwords/resetAdminPassword";
import CompanyRegistration from "./pages/companyRegistration";
import SetPassword from "./pages/passwords/setPassword";
import ExtensionRedirect from "./pages/extensionRedirect";
import GoogleCallback from "./pages/passwords/googleCallback";
import RefreshGoogleAuth from "./pages/passwords/refreshGoogleAuth";

export const SERVER_URL = "https://api.useattic.com";
// export const SERVER_URL = "http://localhost:5002";

function App() {
  return (
    <div className="app-container">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/extensionRedirect" element={<ExtensionRedirect />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/addTeamMember" element={<AddTeamMember />} />
          <Route path="/requestPasswordReset" element={<RequestPasswordReset />} />
          <Route path="/subordinatePasswordReset" element={<SubordinatePasswordReset />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/resetAdminPassword" element={<ResetAdminPassword />} />
          <Route path="/registerCompany" element={<CompanyRegistration />} />
          <Route path="/setPassword" element={<SetPassword type="subordinate" />} />
          <Route path="/completeRegistration" element={<SetPassword type="superadmin" />} />
          <Route path="/googleCallback" element={<GoogleCallback />} />
          <Route path="/refreshGoogleAuth" element={<RefreshGoogleAuth />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
