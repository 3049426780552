/* eslint-disable guard-for-in */
import React, { useEffect, useState } from "react";
import Navbar from "../components/atoms/navbar";
import PersonalAccountsModule from "../components/organisms/personalAccountsModule";
import SharedAccountsModule from "../components/organisms/sharedAccountsModule";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "../components/atoms/loadingScreen";
import { SERVER_URL } from "../App";
import UserDetailsModule from "../components/organisms/userDetailsModule";
import SubordinatePersonalAccountsModule from "../components/organisms/subordinatePersonalAccountsModule";
import SubordinateSharedAccountsModule from "../components/organisms/subordinateSharedAccountsModule";

interface subordnateDashboardProps {
  user: any;
  managedServices: object;
  updateUserData: () => void;
}

function SubordnateDashboard(props: subordnateDashboardProps) {
  return (
    <div className="page-container">
      <Navbar authedOnly={false} />
      <div className="full-page-element">
        <h2 style={{ marginBottom: 8 }}>Welcome to your Attic, {props.user.firstName}</h2>
        <div className="module-container">
          <UserDetailsModule
            manager={false}
            updateUserData={props.updateUserData}
            user={props.user}
            currentUser={true}
          />
          <div className="threefifth-column">
            <SubordinatePersonalAccountsModule user={props.user} />
            <SubordinateSharedAccountsModule user={props.user} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubordnateDashboard;
