import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";

interface navbarProps {
  authedOnly?: boolean;
  logoOnly?: boolean;
}

function Navbar(props: navbarProps) {
  const [authed, setAuthed] = useState(true);
  const [demo, setDemo] = useState(false);

  // Update navbar and redirect if not authed
  const navigate = useNavigate();
  const token = localStorage.getItem("token"); // should verify token here
  useEffect(() => {
    if (!token) {
      setAuthed(false);
      if (props.authedOnly) {
        navigate("/login");
      }
    }
    const demoAcc = localStorage.getItem("demo-account");
    if (demoAcc === "true") {
      setDemo(true);
    }
  }, []);

  // Function to log user out of dashboard
  function logout() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <>
    {demo && <div className="demo-banner">Demo Organisation: feel free to explore and edit!</div>}
      <div className="navbar-cont">
        <h1 onClick={() => navigate("/")} className="title-txt" style={{ cursor: "pointer" }}>
          Attic
        </h1>
        <div className="navbar-buttons">
          <div hidden={authed || props.logoOnly}>
            <button className="button pill right large" onClick={() => navigate("/login")}>
              <h4 className="button-txt">Login</h4>
            </button>
          </div>
          <div onClick={() => logout()} hidden={!authed || props.logoOnly}>
            <button className="button pill right large">
              <h4 className="button-txt">Logout</h4>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
