import React, { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import * as Checkbox from "@radix-ui/react-checkbox";
import { SERVER_URL } from "../../../App";
import LoadingElement from "../loadingElement";

interface AccountOnboardingFormProps {
  userEmail: string;
  userDetails?: any;
  teamEdit: boolean;
  setAccountConfiguration: any;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Figma(props: AccountOnboardingFormProps) {
  const [firstName, setFirstName] = useState("Loading");
  const [surname, setSurname] = useState("Loading");
  const [email, setEmail] = useState("Loading");
  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [teamCheckboxes, setTeamCheckboxes] = useState([]);
  const [teamTicks, setTeamTicks] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getServiceData();
    props.setAccountConfiguration({ teams: selectedTeams, firstName: firstName, surname: surname, email: email });
  }, [props.teamEdit]);

  useEffect(() => {
    setTeams();
  }, [availableTeams]);

  // Function to update account configuration when channels are selected
  async function handleCheckboxChange() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const checkedChannels: any = [];
    await Promise.all(
      availableTeams.map(async (teamName) => {
        const check: any = await document.getElementById(teamName);
        if (check.getAttribute("data-state") === "checked") {
          checkedChannels.push(teamName);
        }
      }),
    );
    props.setAccountConfiguration({ teams: checkedChannels, firstName: firstName, surname: surname, email: email });
  }

  // Collects user's and subordinates data froms server
  async function getServiceData() {
    // setLoading(true);
    props.setLoaded && props.setLoaded(false);
    const url = SERVER_URL + "/dashboard/getManagedServiceDetails";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        accountUser: props.userEmail,
        accountSlug: "figma",
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();
      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=getServiceData&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        console.log(data["msg"]);
      } else {
        const userAccountDetails = data["data"]["userAccountDetails"];
        const companyAccountDetails = data["data"]["companyAccountDetails"];

        setAvailableTeams(companyAccountDetails["configuration"]["teams"]);
        // If the user has an account already, prefill it's details
        if (userAccountDetails !== null) {
          setSelectedTeams(userAccountDetails["accountConfiguration"]["teams"]);
          setFirstName(userAccountDetails["accountConfiguration"]["firstName"]);
          setSurname(userAccountDetails["accountConfiguration"]["surname"]);
          setEmail(userAccountDetails["accountConfiguration"]["email"]);
        } else {
          setFirstName(props.userDetails.firstName);
          setSurname(props.userDetails.surname);
          setEmail(props.userDetails.email);

          if (props.userDetails.config !== undefined) {
            if (Object.keys(props.userDetails.config["figma"][1].teams).length !== 0) {
              setSelectedTeams(props.userDetails.config["figma"][1].teams);
            }
          }
        }

        setLoading(false);
        props.setLoaded && props.setLoaded(true);
        return true;
      }
    } catch (error) {
      console.log(error);
      // Redirect to login
      navigate("/login?error=true&responseStatus=408&trigger=getServiceData");
    }
  }

  // Asynchronously set the user and company details
  async function setTeams() {
    // Set the private teams
    const teamCheckboxesCont: any = [];
    const teamTicksCont: any = [];
    for (const team in availableTeams) {
      const teamName = availableTeams[team];
      const included = selectedTeams.includes(teamName);
      teamCheckboxesCont.push(
        <div className="check-cont">
          <Checkbox.Root
            className="checkbox-root"
            id={teamName}
            value={teamName}
            onClick={() => handleCheckboxChange()}
            defaultChecked={included}
          >
            <Checkbox.Indicator className="checkbox-indicator">
              <IoIosCheckmark size={20} />
            </Checkbox.Indicator>
          </Checkbox.Root>
          <label className="Label" htmlFor={team}>
            <p>{teamName}</p>
          </label>
        </div>,
      );
      if (included) {
        teamTicksCont.push(
          <div className="check-cont">
            <IoIosCheckmark size={20} />
            <label className="Label" htmlFor={team}>
              <p>{teamName}</p>
            </label>
          </div>,
        );
      }
    }
    setTeamTicks(teamTicksCont);
    setTeamCheckboxes(teamCheckboxesCont);
  }

  if (loading) {
    return (
      <div style={{ height: 175 }}>
        <LoadingElement />
      </div>
    );
  } else {
    return (
      <div className="form-cont fill left">
        <div className="green-text-cont left">
          <h4>Basic Account Details</h4>
          <div>
            <h5>First Name: {firstName}</h5>
            <h5>Surname: {surname}</h5>
            <h5>Email: {email}</h5>
            <h5>Password: Randomly Generated</h5>
          </div>
        </div>
        <div className="modal-section">
          <h4>Private Teams</h4>
          <div className="column-cont">
            {props.teamEdit ? teamCheckboxes : teamTicks.length === 0 ? <p>User is in no private teams.</p> : teamTicks}
          </div>
        </div>
      </div>
    );
  }
}

export default Figma;
