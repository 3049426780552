/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../atoms/loadingButton";
import EditSharedApps from "../atoms/editSharedApps";
import { SERVER_URL } from "../../App";
import * as Accordion from "@radix-ui/react-accordion";
import { IoIosArrowDown } from "react-icons/io";
import Slack from "../atoms/accountUIs/slack";
import Figma from "../atoms/accountUIs/figma";
import GoogleWorkspace from "../atoms/accountUIs/googleWorkspace";


function NewTeamMemberForm() {
  // Page 1 Vars
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const email_extension = "@" + (localStorage.getItem("email") as string).split("@")[1];

  // Page 2 Vars
  const [individualApps, setIndividualApps] = useState<{ [key: string]: [string, any] }>({});
  const [individualAppsBill, setIndividualAppsBill] = useState(5.0);

  // Page 3 vars
  const [sharedApps, setSharedApps] = useState([]);

  // Page 4 Varsn
  const appUIs:any = {
    "slack": <Slack userEmail={email} channelEdit={true} userDetails={{ name: firstName + " " + surname, email: email + email_extension, config: individualApps }} setAccountConfiguration={(accountConfig:any) => changeAccountConfiguration("slack", accountConfig)} />,
    "figma": <Figma userEmail={email} teamEdit={true} userDetails={{ firstName: firstName, surname: surname, email: email, config: individualApps }} setAccountConfiguration={(accountConfig:any) => changeAccountConfiguration("figma", accountConfig)} />,
    "googleworkspace": <GoogleWorkspace userEmail={email} userDetails={{ firstName: firstName, surname: surname, email: email }} setAccountConfiguration={() => console.log("")} />
  }

  // Page 5 vars
  const [inviteEmail, setInviteEmail] = useState("");

  // Whole Page Vars
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const supportedServicesString:any = localStorage.getItem('supportedServicesString');
  const supportedServices = JSON.parse(supportedServicesString);
  const managedServicesString:any = localStorage.getItem('managedServicesString');
  const managedServices = JSON.parse(managedServicesString);

  const navigate = useNavigate();

  // Go forward through the form
  function nextStep() {
    setErrorMessage("");
    if (step === 1) {
      const emailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email + email_extension);
      if (firstName === "" || surname === "" || email === "") {
        setErrorMessage("No fields can be blank");
      } else if (!emailValid) {
        setErrorMessage("Not a valid email address.");
      } else {
        setIndividualApps({"googleworkspace": ["Business Starter", {email: email + email_extension, firstName: firstName, surname: surname}]});
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (Object.keys(individualApps).length > 1) {
        setStep(3);
      } else {
        setStep(4); // skip configure apps page
      }
    } else {
      setStep(step + 1);
    }
  }

  // Go back through the form
  function previousStep() {
    if (step === 4) {
      if (Object.keys(individualApps).length > 1) {
        setStep(3);
      } else {
        setStep(2); // skip configure apps page
      }
    } else {
      setStep(step - 1);
    }
  }

  // Function to select and individual app
  function indAppSelect(serviceSlug: string) {
    
    // TEMP SOLUTION AS ACCORDIANS AREN'T PRELOADING
    const appDefaults:any = {
      "slack": {channels: [], displayName: firstName, email: email},
      "figma": {teams: [], firstName: firstName, surname: surname, email: email}
    }

    const plan = managedServices[serviceSlug].users[Object.keys(managedServices[serviceSlug].users)[0]];
    const price = supportedServices[serviceSlug].plans[plan]
    const newSelectedIndApps:any = individualApps;

    if (serviceSlug in newSelectedIndApps) {
      delete newSelectedIndApps[serviceSlug];
      setIndividualAppsBill(individualAppsBill - price);
    } else {
      newSelectedIndApps[serviceSlug] = [plan, appDefaults[serviceSlug]]
      setIndividualAppsBill(individualAppsBill + price);
    }

    setIndividualApps(newSelectedIndApps);
  }

  // Change selected individual account configuration
  function changeAccountConfiguration(accountSlug:string, accountConfig: any) {
    setIndividualApps(prevIndividualApps => ({
      ...prevIndividualApps,
      [accountSlug]: [prevIndividualApps[accountSlug][0], accountConfig]
    }));
  
  }

  // Function to submit entire form at last step
  async function submitForm() {

    const url = SERVER_URL + "/dashboard/RegisterUser";
    const token = localStorage.getItem("token");

    // All fields must have data
    if (inviteEmail === "") {
      setErrorMessage("You must include an invite email");
      return Promise.reject(new Error("You must include an invite email"));
    }

    // Email validation
    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inviteEmail);
    if (!emailFormat) {
      setErrorMessage("Not a valid email address.");
      return Promise.reject(new Error("Not a valid email address."));
    }

     // Set server timeout
     const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 35000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        newEmail: email + email_extension,
        firstName: firstName,
        surname: surname,
        accountType: "Managed",
        managedServices: individualApps,
        sharedApps: sharedApps,
        inviteEmail: inviteEmail,
      }),
    })

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([
        responsePromise,
        timeoutPromise,
      ]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=NewTeamMember&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      
      } else if (!data["success"]) {
        setErrorMessage(data.msg);
        return Promise.reject(new Error(data.msg));
      
      } else {
        navigate("/");
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      navigate("/login?error=true&responseStatus=408&trigger=newTeamMember");
    }
  }

  return (
    <div>
      {step === 1 && (
        <div className="form-cont wide">
          <h2>Add your team member</h2>
          <div className="half-input-cont">
            <input
              className="halfinput"
              type="text"
              autoComplete="off"
              placeholder="First Name"
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
            />
            <input
              className="halfinput"
              type="text"
              autoComplete="off"
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
              placeholder="Surname"
              onChange={(event) => setSurname(event.target.value)}
              value={surname}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
            <input
              type="text"
              autoCapitalize="off"
              placeholder="Handle"
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
              autoComplete="off"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              style={{ marginRight: "15px" }}
            />
            <h4>{email_extension}</h4>
          </div>
          <p>This Google Workspace email address will be created and used as the login for all other accounts.</p>

          <button
            id="submit-btn"
            className="submit-button"
            onClick={() => {
              nextStep();
            }}
          >
            <h4 className="button-txt">Continue</h4>
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="form-cont wide">
          <div className="form-cont wide">
            <h2>Give {firstName} some individual apps</h2>
            <div className="app-icon-button-grid-cont">
              {Object.keys(supportedServices).map((serviceSlug:string) => {
                if (serviceSlug !== "googleworkspace" && (serviceSlug in managedServices)) {
                  return (
                    <div
                      className={`app-icon-button-cont ${(serviceSlug in individualApps) ? "selected" : ""}`}
                      onClick={() => indAppSelect(serviceSlug)}
                    >
                      <img src={supportedServices[serviceSlug].icon192} className="app-icon" />
                      <p>{supportedServices[serviceSlug].name}</p>
                    </div>
                  )}
                })
              }
            </div>
            <div className="green-text-cont left">
              <h4>Total Monthly Cost - £{individualAppsBill.toFixed(2)}</h4>
              {Object.keys(individualApps).length == 0 ? (
                <p>You haven't selected any apps.</p>
              ) : (
                <ul>
                  {Object.keys(individualApps).map((serviceSlug) => (
                    <p>
                      <li key={serviceSlug}>
                        {supportedServices[serviceSlug].name} - £{supportedServices[serviceSlug].plans[individualApps[serviceSlug][0]].toFixed(2)}
                      </li>
                    </p>
                  ))}
                </ul>
              )}
            </div>
            <button
              id="submit-btn-2"
              className="submit-button"
              onClick={() => {
                nextStep();
              }}
            >
              <h4 className="button-txt">Continue</h4>
            </button>
            <a style={{cursor: "pointer"}} onClick={previousStep}>{"< "}Previous Step</a>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="form-cont wide">
          <div className="form-cont wide">
            <h2>Configure {firstName}'s apps</h2>
              <Accordion.Root className="accordion-component" type="single">
                <div className="form-cont wide">
                {Object.keys(individualApps).map((serviceSlug:string) => {
                  if (serviceSlug !== "googleworkspace") {
                    return (
                      <Accordion.Item className="accordion-item" value={serviceSlug} key={serviceSlug}>
                        <Accordion.AccordionTrigger className="accordion-trigger">
                          <div className="icon-text-cont">
                            <img className="app-icon small" src={supportedServices[serviceSlug].icon192}></img>
                            <h4>{supportedServices[serviceSlug].name}</h4>
                          </div>
                          <IoIosArrowDown style={{ marginRight: 10 }} size={20} className={"accordion-chevron"} />
                        </Accordion.AccordionTrigger>
                        
                        <Accordion.AccordionContent className="accordion-content">
                          {appUIs[serviceSlug]}
                        </Accordion.AccordionContent>
                      </Accordion.Item>
                    )}
                  })
                }
                </div>
              </Accordion.Root>
            <button
              className="submit-button"
              onClick={() => {
                nextStep();
              }}>
              <h4 className="button-txt">Continue</h4>
            </button>
            <a style={{cursor: "pointer"}} onClick={previousStep}>{"< "}Previous Step</a>
          </div>
        </div>
      )}

      {step === 4 && (
        <div className="form-cont wide">
          <div className="form-cont wide">
            <h2>Share some apps with {firstName}</h2>
            <EditSharedApps sharedApps={sharedApps} setSharedApps={setSharedApps} />
            <button
              className="submit-button"
              onClick={() => {
                nextStep();
              }}
            >
              <h4 className="button-txt">Continue</h4>
            </button>
            <a style={{cursor: "pointer"}} onClick={previousStep}>{"< "}Previous Step</a>
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="form-cont wide">
          <h2>Complete {firstName}'s Attic</h2>
          <div className="modal-body">
            <div className="modal-section">
              <div className="module-component-header">
                <h3>Individual Accounts</h3>
                <button className="button pill secondary right" onClick={() => setStep(2)}>
                  <h5>Edit</h5>
                </button>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", rowGap: 8, columnGap: 8 }}>
                {Object.keys(individualApps).map((serviceSlug) => (
                  <div className="edit-app-tag">
                    <div className="icon-text-cont">
                      <img className="app-icon small" src={supportedServices[serviceSlug].icon192}></img>
                      <h4>{supportedServices[serviceSlug].name}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="modal-section">
              <div className="module-component-header">
                <h3>Shared Accounts</h3>
                <button className="button pill secondary right" onClick={() => setStep(4)}>
                  <h5>Edit</h5>
                </button>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", rowGap: 8, columnGap: 8 }}>
                {sharedApps.map((app) => (
                  <div className="edit-app-tag">
                    <p>{app}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="modal-section">
              <h3>Invite Details</h3>
              <p>We need an address to email {firstName} the link to create their account</p>
              <input
                className="input"
                autoCapitalize="off"
                type="text"
                placeholder="Invite Email"
                onKeyDown={(event) => event.key === "Enter" && document.getElementById("create-btn")?.click()}
                autoComplete="off"
                onChange={(event) => setInviteEmail(event.target.value)}
                value={inviteEmail}
              />
            </div>
          </div>
          <div className="modal-section">
            <div className="green-text-cont left">
              <h4>Total Monthly Cost - £{(individualAppsBill + 9.99).toFixed(2)}</h4>
              <ul>
                <p>
                  <li key={"apps"}>Individual apps - £{individualAppsBill.toFixed(2)}</li>
                </p>
                <p>
                  <li key={"apps"}>Attic License - £9.99</li>
                </p>
              </ul>
            </div>
          </div>
          <LoadingButton text="Create Account" onClick={submitForm} id="create-btn" />
          <p>Building the accounts may take a few seconds.</p>
        </div>
      )}
    
      <p className="error-txt" style={{marginTop:8}}>{errorMessage}</p>
    </div>
  );
}

export default NewTeamMemberForm;
