import React, { useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";

export default function LoadingElement(props: { spinnerSize?: number }) {
  return (
    <div className="loading-element-container">
      <MoonLoader
        color="#20513b"
        cssOverride={{
          display: "flex",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        loading
        size={props.spinnerSize ? props.spinnerSize : 20}
        speedMultiplier={0.75}
      />
    </div>
  );
}
