/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { SERVER_URL } from "../../../App";
import LoadingButton from "../../atoms/loadingButton";

function RequestPasswordResetForm() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrormessage] = useState("");
  const [hidden, setHidden] = useState(false);

  function submitForm() {
    const url = SERVER_URL + "/atticaccounts/RequestPasswordReset";
    // All fields must have data
    if (email === "") {
      setErrormessage("No fields can be blank");
      return Promise.reject(new Error("No fields can be blank."));
    }

    // Email validation
    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    if (!emailFormat) {
      setErrormessage("Not a valid email address.");
      return Promise.reject(new Error("Not a valid email address."));
    }
    return fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => {
        const data: any = res.json();
        return data;
      })
      .then((data) => {
        if (data["success"]) {
          setHidden(true);
          return Promise.resolve();
        } else {
          setErrormessage(data["msg"]);
          return Promise.reject(new Error(data["msg"]));
        }
      });
  }

  return (
    <div>
      <div hidden={hidden}>
        <div className="form-cont" hidden={hidden}>
          <h2>Enter your email to reset your password</h2>
          <input
            type="text"
            autoCapitalize="off"
            placeholder="example@chant.dev"
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("reset-btn")?.click()}
            value={email}
          />

          <LoadingButton id="reset-btn" text="Reset Password" onClick={submitForm} />

          <div className="form-error-cont" style={{ padding: 0 }}>
            <p className="error-txt">{errorMessage}</p>
          </div>
        </div>
      </div>

      <div className="full-page-message content-container" hidden={!hidden}>
        <h2>Now it's over to your manager.</h2>
        <p>
          Before you can reset your password, your manager needs to approve the request using the email we just sent
          them. Once they do, we will email you a link to continue. So, if nothing happens, maybe give them a poke.
        </p>
      </div>
    </div>
  );
}

export default RequestPasswordResetForm;
