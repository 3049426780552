/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../App";
import LoadingButton from "../atoms/loadingButton";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFA, setTwoFA] = useState("");
  const [show2FA, setShow2FA] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [errorMessage, setErrormessage] = useState("");
  const [twoFAFocussed, set2FAFocussed] = useState(false);
  const navigate = useNavigate();

  function submitForm() {
    const url = SERVER_URL + "/atticaccounts/login/";

    // All fields must have data
    if (email === "" || password === "") {
      setErrormessage("No fields can be blank");
      return Promise.reject(new Error("No fields can be blank"));
    }

    // Email validation
    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    if (!emailFormat) {
      setErrormessage("Not a valid email address.");
      return Promise.reject(new Error("Not a valid email address."));
    }

    return fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then(async (res) => {
        const resJSON = await res.json();
        if (resJSON["success"] != undefined) {
          resJSON.status = res.status;
          return resJSON;
        } else {
          throw res;
        }
      })
      .then((resJSON) => {
        if (resJSON["success"]) {
          console.log("login successful");
          if (resJSON["token"]) {
            localStorage.setItem("token", resJSON["token"]);
            localStorage.setItem("email", email);
            navigate("/");
            return Promise.resolve();
          } else {
            setShow2FA(true);
            return Promise.resolve();
          }
        } else {
          throw Error(resJSON.msg);
        }
      })
      .catch((error) => {
        if (error.status) {
          console.log("error logging in: " + error);
          setErrormessage("Something went wrong: " + error.status);
        } else {
          console.log("error logging in: " + error);
          setErrormessage("Something went wrong:" + error);
        }
        return;
      });
  }

  // Form to submit 2FA code to return code
  function submit2FA() {
    const url = SERVER_URL + "/atticaccounts/verify2FA";

    return fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        code: twoFA,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            setErrormessage(data.msg);
            throw new Error(data.msg);
          });
        }
      })
      .then((data) => {
        localStorage.setItem("token", data["token"]);
        localStorage.setItem("email", email);
        navigate("/");
        return Promise.resolve();
      });
  }

  return (
    <div>
      <div hidden={show2FA}>
        <div className="form-cont">
          <h2>Manage your Attic</h2>
          <input
            type="email"
            autoCapitalize="off"
            placeholder="Email"
            autoComplete="email"
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <div style={{ position: "relative" }}>
            <input
              type={passwordHidden ? "password" : "text"}
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
              value={password}
            />
            {passwordHidden ? (
              <HiOutlineEye onClick={() => setPasswordHidden(false)} className="password-icon" size={20} />
            ) : (
              <HiOutlineEyeSlash onClick={() => setPasswordHidden(true)} className="password-icon" size={20} />
            )}
          </div>

          <LoadingButton id="submit-btn" text="Login" onClick={submitForm} />
          <p>
            <a href="/requestPasswordReset">Reset Password</a>
          </p>
          <div className="form-error-cont" style={{ padding: 0 }}>
            <p className="error-txt">{errorMessage}</p>
          </div>
        </div>
      </div>

      <div hidden={!show2FA}>
        <div className="form-cont">
          <div>
            <h2>One more step...</h2>
            <p>
              We've sent a code to your personal email address. Could you confirm it so we definitely know this is you?
            </p>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              gap: 16,
              justifyContent: "space-between",
              width: "100%",
              height: 64,
              marginBottom: 8,
            }}
            onClick={() => document.getElementById("mce-CNAME")?.focus()}
          >
            <input
              className={`${
                (twoFAFocussed && twoFA === "") || twoFA.length === 6 ? "split-input-focussed" : null
              } split-input-bg`}
              value={twoFA[0] || ""}
            />
            <input
              className={`${
                (twoFAFocussed && !twoFA[1] && twoFA[0]) || twoFA.length === 6 ? "split-input-focussed" : null
              } split-input-bg`}
              value={twoFA[1] || ""}
            />
            <input
              className={`${
                (twoFAFocussed && !twoFA[2] && twoFA[1]) || twoFA.length === 6 ? "split-input-focussed" : null
              } split-input-bg`}
              value={twoFA[2] || ""}
            />
            <input
              className={`${
                (twoFAFocussed && !twoFA[3] && twoFA[2]) || twoFA.length === 6 ? "split-input-focussed" : null
              } split-input-bg`}
              value={twoFA[3] || ""}
            />
            <input
              className={`${
                (twoFAFocussed && !twoFA[4] && twoFA[3]) || twoFA.length === 6 ? "split-input-focussed" : null
              } split-input-bg`}
              value={twoFA[4] || ""}
            />
            <input
              className={`${
                (twoFAFocussed && !twoFA[5] && twoFA[4]) || twoFA.length === 6 ? "split-input-focussed" : null
              } split-input-bg`}
              value={twoFA[5] || ""}
            />
            <input
              type="number"
              autoComplete="off"
              onFocus={() => set2FAFocussed(true)}
              onBlur={() => set2FAFocussed(false)}
              onKeyDown={(event) => {
                if (twoFA.length === 6 && /^[0-9]{1}$/i.test(event.key)) {
                  setTwoFA("");
                }
                event.key === "Enter" && document.getElementById("submit-2fa")?.click();
              }}
              placeholder=""
              maxLength={6}
              style={{
                textAlign: "center",
                backgroundColor: "transparent",
                border: "none",
                zIndex: 99,
                opacity: 0,
                height: "100%",
                position: "absolute",
                width: "100%",
              }}
              onChange={(event) => setTwoFA(event.target.value)}
              value={twoFA}
            />
          </div>
          <LoadingButton text="Continue" id="submit-2fa" onClick={submit2FA} />

          <div className="form-error-cont" style={{ padding: 0 }}>
            <p className="error-txt">{errorMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
