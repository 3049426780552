export function triggerOffboarding(userEmail: string, removedAppNames: string[]) {
  console.log("Offboarding user", userEmail, "from apps", removedAppNames.toString());
  const supportedServices = localStorage.getItem("supportedServices");

  if (supportedServices) {
    removedAppNames.forEach(async (appName) => {
      const appSlug = await JSON.parse(supportedServices).find((service: any) => service.name === appName).slug;
      window.dispatchEvent(
        new CustomEvent("managerOffboardApp", {
          detail: { userEmail: userEmail, appSlug: appSlug },
        }),
      );
    });
  }
}

export async function triggerOnboarding(userEmail: string, addedAppSlugs: string[]) {
  console.log("Onboard user", userEmail, "to apps", addedAppSlugs.toString());
  for await (const appSlug of addedAppSlugs) {
    window.dispatchEvent(
      new CustomEvent("managerOnboardApp", {
        detail: { userEmail: userEmail, appSlug: appSlug },
      }),
    );
  }
  return;
}
