/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { SERVER_URL } from "../../../App";
import LoadingButton from "../../atoms/loadingButton";

type RequestPasswordResetForm = {
  resetToken: any;
  onSubmit: any;
};

function ResetPasswordForm({ resetToken, onSubmit }: RequestPasswordResetForm) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrormessage] = useState("");

  function submitForm() {
    const url = SERVER_URL + "/atticaccounts/ResetPassword";

    // All fields must have data
    if (password1 === "" || password2 === "") {
      setErrormessage("No fields can be blank");
      return Promise.reject(new Error("No fields can be blank"));
    }

    // Password Validation
    const passLength = password1.length > 12 ? true : false;
    const passSpecialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const passSymbols = passSpecialChars.test(password1);
    const passNumber = /\d+/.test(password1);
    const passCapital = /[A-Z]/.test(password1);

    if (!passLength || !passCapital || !passNumber || !passSymbols) {
      setErrormessage("Passwords must be at least 12 charactors, and include numbers, capitals, and symbols.");
      return Promise.reject(
        new Error("Passwords must be at least 12 charactors, and include numbers, capitals, and symbols."),
      );
    } else if (password1 !== password2) {
      setErrormessage("The password's don't match.");
      return Promise.reject(new Error("The password's don't match."));
    }

    return fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + resetToken,
      },
      body: JSON.stringify({
        newPassword: password1,
      }),
    })
      .then((res) => {
        const data: any = res.json();
        return data;
      })
      .then((data) => {
        if (data["success"]) {
          onSubmit();
          return Promise.resolve();
        } else {
          setErrormessage(data["msg"]);
          return Promise.reject(new Error(data["msg"]));
        }
      });
  }

  return (
    <div>
      <div className="form-cont">
        <h2>Set your new password</h2>
        <input
          type="password"
          autoComplete="off"
          placeholder="Password"
          onKeyDown={(event) => event.key === "Enter" && document.getElementById("reset-btn-2")?.click()}
          onChange={(event) => setPassword1(event.target.value)}
          value={password1}
        />
        <input
          type="password"
          autoComplete="off"
          placeholder="Confirm Password"
          onChange={(event) => setPassword2(event.target.value)}
          onKeyDown={(event) => event.key === "Enter" && document.getElementById("reset-btn-2")?.click()}
          value={password2}
        />

        <LoadingButton id="reset-btn-2" text="Reset Password" onClick={submitForm} />

        <div className="form-error-cont" style={{ padding: 0 }}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
