/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosClose, IoIosAdd } from "react-icons/io";
import LoadingButton from "../atoms/loadingButton";
import "../../App.css";
import { SERVER_URL } from "../../App";
import { triggerOffboarding } from "../../utils/triggerExtension";

interface DeleteIndividualAccountProps {
  user: any;
  updateUserData: () => void;
}

function DeleteIndividualAccount(props: DeleteIndividualAccountProps) {
  const [currentAppTags, setCurrentAppTags] = useState([]);
  const [removedAppTags, setRemovedAppTags] = useState([]);
  const [currentApps, setCurrentApps] = useState([]);
  const [removedApps, setRemovedApps] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const [nextStep, setNextStep] = useState(false);

  async function getApps() {
    const currentApps: any = [];
    for (const app in props.user.individualLogins) {
      // Google workspace can't be deleted as it is linked to the email
      if (app !== "Google Workspace") {
        await currentApps.push(app);
      }
    }

    setCurrentApps(currentApps);
    return currentApps;
  }

  async function DeleteApps() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/DeleteIndividualAccounts";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        apps: removedApps,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=DeleteIndivdualApps&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        triggerOffboarding(props.user.email, removedApps);
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=DeleteIndivdualApps");
    }
  }

  useEffect(() => {
    getApps().then((apps) => {
      console.log(apps);
    });
  }, []);

  async function updateAppTags() {
    const newCurrentAppTags: any = [];
    for (const app in currentApps) {
      await newCurrentAppTags.push(
        <div className="edit-app-tag" onClick={() => removeApp(currentApps[app])}>
          <p>{currentApps[app]}</p>
          <IoIosClose style={{ margin: "auto" }} size={22} />
        </div>,
      );
    }
    const newRemovedAppTags: any = [];
    for (const app in removedApps) {
      await newRemovedAppTags.push(
        <div className="edit-app-tag remove" onClick={() => addApp(removedApps[app])}>
          <p>{removedApps[app]}</p>
          <IoIosAdd style={{ margin: "auto" }} size={22} />
        </div>,
      );
    }

    setRemovedAppTags(newRemovedAppTags);
    setCurrentAppTags(newCurrentAppTags);
  }

  async function addApp(app: string) {
    const newCurrentApps: any = await [...currentApps, app];
    const newRemovedApps: any = await removedApps.filter((e) => e !== app);

    setRemovedApps(newRemovedApps);
    setCurrentApps(newCurrentApps);

    updateAppTags();
  }

  async function removeApp(app: string) {
    const newRemovedApps: any = [...removedApps, app];
    const newCurrentApps: any = currentApps.filter((e) => e !== app);

    setRemovedApps(newRemovedApps);
    setCurrentApps(newCurrentApps);

    updateAppTags();
  }

  useEffect(() => {
    updateAppTags();
  }, [currentApps]);

  function step2() {
    if (removedAppTags.length === 0) {
      setErrorMessage("No apps marked as needing deleted");
    } else {
      setErrorMessage("");
      setNextStep(true);
    }
  }

  return (
    <div>
      <div hidden={nextStep}>
        <div className="modal-body">
          <div className="modal-section">
            <h4>Current Apps</h4>
            <div className="add-apps-container">{currentAppTags}</div>
          </div>
          <div className="modal-section">
            <h4>Apps to Remove</h4>
            <div className="remove-apps-container">{removedAppTags}</div>
          </div>
          <button
            className="submit-button"
            onClick={() => step2()}
            style={{ backgroundColor: "#9E3120", borderColor: "#9E3120" }}
          >
            <h4 className="button-txt">Continue</h4>
          </button>
          <div hidden={errorMessage === ""}>
            <p className="error-txt">{errorMessage}</p>
          </div>
        </div>
      </div>
      <div hidden={!nextStep}>
        <div className="modal-body">
          <div>
            <h4>By hitting delete, {props.user.firstName} will loose the following apps:</h4>
            <ul>
              {Object.keys(removedApps).map((app: any) => (
                <li key={app}>{removedApps[app]}</li>
              ))}
            </ul>
          </div>
          <h4>Once deleted, the login details can't be recovered.</h4>
          {errorMessage ? <p>{errorMessage}</p> : null}
          <LoadingButton text="Delete" onClick={DeleteApps} singleUse={true} warning={true} />
          <div hidden={errorMessage === ""}>
            <p className="error-txt">{errorMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteIndividualAccount;
