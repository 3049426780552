import React, { useState, useEffect } from "react";
import Navbar from "../../components/atoms/navbar";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { SERVER_URL } from "../../App";
import GetGoogleAuth from "../../components/atoms/getGoogleAuth";

function GoogleCallback() {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrormessage] = useState<null | string>(null);
  const token = localStorage.getItem("token");

  let flag = true;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log("here");
    submitCallbackData(params);
  }, []);

  function submitCallbackData(params: { [k: string]: string }) {
    if (flag) { // link is one use
      flag = false;
      const url = SERVER_URL + "/atticaccounts/gcloudcallback";

      return fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: params,
        }),
      })
        .then((res) => {
          console.log(res);
          if (res.ok) {
            setLoading(false);
            return;
          } else {
            return res.json().then((data) => {
              setErrormessage(data.msg);
              setLoading(false);
            });
          }
        })
        .catch((error) => {
          setErrormessage("Unhandled error: " + error);
          setLoading(false);
        });
    }
  }

  return (
    <div className="page-container">
      <Navbar authedOnly={false} />
      {loading ? (
        <div>
          <div className="center-page-element full-page-message">
            <h2>Just checking a few things...</h2>
            <MoonLoader color="#20513B" cssOverride={{}} loading size={50} speedMultiplier={0.75} />
          </div>
        </div>
      ) : (
        <div>
          {!errorMessage ? (
            <div className="center-page-element full-page-message">
              <h2>You're all done. Are you ready to get started?</h2>
              <p>Your google is connected. Your account has been updated, and you are able to log in. </p>
              <button
                className="button pill x-large"
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                  <h4 className="button-txt">Go to Login</h4>
                  <IoArrowForwardOutline size={22} />
                </div>
              </button>
            </div>
          ) : (
            <div className="center-page-element full-page-message">
              <h2>Oh no! Your google wasn't connected. Please try again.</h2>
              <p>{errorMessage}</p>
              <GetGoogleAuth token={token as string} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GoogleCallback;
