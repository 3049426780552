import { useEffect, useState } from "react";
import LoadingButton from "./loadingButton";
import { SERVER_URL } from "../../App";
import { useNavigate } from "react-router-dom";

function ChangeAccountAccess(props: { user: any; appName: string; updateUserData: () => void }) {
  const [showDelConfimation, setShowDelConfimation] = useState(false);
  const [showFreezeConfimation, setShowFreezeConfimation] = useState(false);
  const [frozen, setFrozen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [view, setView] = useState<"main" | "frozen" | "freeze" | "delete">("main");

  const navigate = useNavigate();

  useEffect(() => {
    if (props.user.individualLogins[props.appName].frozen) {
      setView("frozen");
    }
  }, []);

  async function freezeAccount() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/FreezeIndividualAccount";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        appName: props.appName,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=FreezeIndividualAccount&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=FreezeIndividualAccount");
    }
  }

  async function unFreezeAccount() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/UnFreezeIndividualAccount";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        appName: props.appName,
        unFreeze: true,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=UnFreezeIndividualAccount&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=UnFreezeIndividualAccount");
    }
  }

  async function deleteAccount() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/DeleteIndividualAccounts";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        apps: [props.appName],
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=DeleteIndivdualApps&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=DeleteIndivdualApps");
    }
  }

  if (view === "delete") {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Change Access</h4>
          <button
            onClick={() => {
              setView("main");
            }}
            className="button pill secondary right"
          >
            <h5>Cancel</h5>
          </button>
        </div>
        <p style={{ textAlign: "center", color: "#9e3120" }}>This cannot be undone</p>
        <LoadingButton text="Delete" onClick={deleteAccount} singleUse={true} warning={true} />
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  } else if (view === "freeze") {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Change Access</h4>
          <button
            onClick={() => {
              setView("main");
            }}
            className="button pill secondary right"
          >
            <h5>Cancel</h5>
          </button>
        </div>
        <p style={{ textAlign: "center" }}>You will still maintain access</p>
        <LoadingButton text="Freeze Account" onClick={freezeAccount} singleUse={true} />
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  } else if (view === "frozen") {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Change Access</h4>
          <button
            onClick={() => {
              setView("delete");
            }}
            className="button pill secondary right"
          >
            <h5>Delete</h5>
          </button>
        </div>
        <p>This account is frozen. Un-freeze and {props.user.firstName} will regain access</p>
        <LoadingButton text="Un-Freeze Account" onClick={unFreezeAccount} singleUse={true} />
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Change Access</h4>
          <div className="button-cont row">
            <button onClick={() => setView("freeze")} className="button pill secondary right">
              <h5>Freeze</h5>
            </button>
            <button onClick={() => setView("delete")} className="button pill secondary">
              <h5>Delete</h5>
            </button>
          </div>
        </div>
        <p>
          Freezing will stop {props.user.firstName}'s ability to access it, but the account will remain. Deleting means
          all of it's files and content will be lost, and any bills will cease.
        </p>
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  }
}

export default ChangeAccountAccess;
