/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown, IoIosClose, IoIosAdd } from "react-icons/io";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import LoadingScreen from "./loadingScreen";
import { SERVER_URL } from "../../App";
import { IoArrowForwardOutline } from "react-icons/io5";

function GetGoogleAuth(props: { token: string }) {
  async function getAuthURL() {
    const url = SERVER_URL + "/atticaccounts/getGoogleAuthUrl";

    // Fetch data from the server
    return (
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.token,
        },
      })
        // Extract the JSON response and return it as a promise
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Error getting google auth URL! Status: ${res.status}`);
          }
          return res.json();
        })
        // If the response indicates success, add the shared apps to the array
        .then((data) => {
          if (data.url) {
            window.open(data.url);
          }
        })
        // Handle any errors that may have occurred
        .catch((error) => {
          console.error(error);
        })
    );
  }

  return (
    <button
      className="button pill x-large"
      onClick={() => {
        getAuthURL();
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
        <h4 className="button-txt">Authorise Google</h4>
        <IoArrowForwardOutline size={22} />
      </div>
    </button>
  );
}

export default GetGoogleAuth;
