import React, { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosCheckmark } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import * as Checkbox from "@radix-ui/react-checkbox";
import { SERVER_URL } from "../../../App";
import LoadingElement from "../loadingElement";

interface AccountOnboardingFormProps {
  userEmail: string;
  userDetails?: any;
  channelEdit: boolean;
  setAccountConfiguration: any;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Slack(props: AccountOnboardingFormProps) {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [availableChannels, setAvailableChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [channelCheckboxes, setChannelCheckboxes] = useState([]);
  const [channelTicks, setChannelTicks] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // Set which channels can be collected
  useEffect(() => {
    setChannels();
  }, [availableChannels]);

  // Get data for service and set default configuration on creation
  useEffect(() => {
    getServiceData();
    props.setAccountConfiguration({ channels: selectedChannels, displayName: displayName, email: email });
  }, [props.channelEdit]);

  // Function to update account configuration when channels are selected
  async function handleCheckboxChange() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const checkedChannels: any = [];
    await Promise.all(
      availableChannels.map(async (channelName) => {
        const check: any = await document.getElementById(channelName);
        if (check.getAttribute("data-state") === "checked") {
          checkedChannels.push(channelName);
        }
      }),
    );
    props.setAccountConfiguration({ channels: checkedChannels, displayName: displayName, email: email });
  }

  // Collects user's and subordinates data froms server
  async function getServiceData() {
    // setLoading(true);
    props.setLoaded && props.setLoaded(false);

    const url = SERVER_URL + "/dashboard/getManagedServiceDetails";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        accountUser: props.userEmail,
        accountSlug: "slack",
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=getServiceData&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        console.log(data["msg"]);
      } else {
        const userAccountDetails = data["data"]["userAccountDetails"];
        const companyAccountDetails = data["data"]["companyAccountDetails"];

        // If the user has an account already, prefill it's details
        if (userAccountDetails !== null) {
          setSelectedChannels(userAccountDetails["accountConfiguration"]["channels"]);
          setDisplayName(userAccountDetails["accountConfiguration"]["displayName"]);
          setEmail(userAccountDetails["accountConfiguration"]["email"]);
        } else {
          setDisplayName(props.userDetails.name);
          setEmail(props.userDetails.email);

          if (props.userDetails.config !== undefined) {
            if (Object.keys(props.userDetails.config["slack"][1].channels).length !== 0) {
              setSelectedChannels(props.userDetails.config["slack"][1].channels);
            }
          }
        }

        setAvailableChannels(companyAccountDetails["configuration"]["channels"]);

        setLoading(false);
        props.setLoaded && props.setLoaded(true);
        return true;
      }
    } catch (error) {
      console.log(error);
      // Redirect to login
      navigate("/login?error=true&responseStatus=408&trigger=getServiceData");
    }
  }

  // Asynchronously set the user and company details
  async function setChannels() {
    // Set the private channels
    const channelCheckboxesCont: any = [];
    const channelTicksCont: any = [];
    for (const channel in availableChannels) {
      const channelName = availableChannels[channel];
      const included = selectedChannels.includes(channelName);
      channelCheckboxesCont.push(
        <div className="check-cont">
          <Checkbox.Root
            className="checkbox-root"
            id={channelName}
            value={channelName}
            onClick={() => handleCheckboxChange()}
            defaultChecked={included}
          >
            <Checkbox.Indicator className="checkbox-indicator">
              <IoIosCheckmark size={24} />
            </Checkbox.Indicator>
          </Checkbox.Root>
          <label className="Label" htmlFor={channel}>
            <p>{channelName}</p>
          </label>
        </div>,
      );
      if (included) {
        channelTicksCont.push(
          <div className="check-cont">
            <IoIosCheckmark size={24} />
            <label className="Label" htmlFor={channel}>
              <p>{channelName}</p>
            </label>
          </div>,
        );
      }
    }
    setChannelTicks(channelTicksCont);
    setChannelCheckboxes(channelCheckboxesCont);
  }

  if (loading) {
    return (
      <div style={{ height: 175 }}>
        <LoadingElement />
      </div>
    );
  } else {
    return (
      <div className="form-cont fill left">
        <div className="green-text-cont left">
          <h4>Basic Account Details</h4>
          <div>
            <h5>Display Name: {displayName}</h5>
            <h5>Email: {email}</h5>
            <h5>Password: Randomly Generated</h5>
          </div>
        </div>
        <div className="modal-section">
          <h4>Private Channels</h4>
          <div className="column-cont">
            {props.channelEdit ? (
              channelCheckboxes
            ) : channelTicks.length === 0 ? (
              <p>{displayName} is in no private channels.</p>
            ) : (
              channelTicks
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Slack;
