import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Accordion from "@radix-ui/react-accordion";
import { IoIosArrowDown } from "react-icons/io";
import AccountOnboardingForm from "../molecules/accountOnboardingForm";
import AddUnmanagedIndividualAccount from "../molecules/addUnmanagedIndividualAccount";
import DeleteIndividualAccount from "../molecules/deleteIndividualAppsForm";
import IndAppCardandModal from "../molecules/indAppCard&Modal";

interface PersonalAccountsModuleProps {
  user: any;
  currentUser: boolean;
  managedServices: { [key: string]: any };
  updateUserData: () => void;
}

function PersonalAccountsModule(props: PersonalAccountsModuleProps) {
  const [personalApps, setPersonalApps] = useState<string[]>([]);
  const [appCards, setAppCards] = useState([]);
  const [onboardingView, setOnboardingView] = useState("");
  const [newSupportedServices, setNewSupportedServices] = useState<any>({});

  useEffect(() => {
    fillApps().then((appCards) => {
      setAppCards(appCards);
    });
  }, []);

  async function fillApps() {
    const supportedServicesString: any = localStorage.getItem("supportedServicesString");
    const supportedServices = JSON.parse(supportedServicesString);
    const managedServicesString: any = localStorage.getItem("managedServicesString");
    const managedServices = JSON.parse(managedServicesString);
    const individualLogins: any = [];
    const appCards: any = [];
    const newSupported = supportedServices;
    for (const name in props.user.individualLogins) {
      const appInstance = props.user.individualLogins[name];
      const serviceDetail = supportedServices[appInstance.slug];
      const orgServiceDetail = managedServices[appInstance.slug];
      if (serviceDetail !== undefined) {
        delete newSupported[appInstance.slug];
      }
      await appCards.push(
        <IndAppCardandModal
          user={props.user}
          name={name}
          updateUserData={props.updateUserData}
          lastUsed={props.user.individualLogins[name].lastLogin}
          serviceDetail={serviceDetail}
          appInstance={appInstance}
          orgServiceDetail={orgServiceDetail}
        />,
      );
      await individualLogins.push(name);
    }

    setNewSupportedServices(newSupported);
    setPersonalApps(individualLogins);
    return appCards;
  }

  return (
    <div className="half-height-module">
      <div className="blur_div" hidden={props.user.twoFAEmail !== undefined}></div>
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">Individual Accounts</h3>
          <p>The accounts that only {props.user.firstName} uses.</p>
        </div>
        {/* {true ? ( */}
        <div className="button-cont row">
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="button pill secondary right">
                <h5>Remove</h5>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="modal-overlay" />
              <Dialog.Content className="modal-cont">
                <div className="modal-header">
                  <div className="title-subtitle-cont">
                    <h3>Delete Stored Individual Apps</h3>
                    <p>By removing apps, {props.user.firstName} will lose one-click access.</p>
                  </div>
                </div>
                <DeleteIndividualAccount updateUserData={props.updateUserData} user={props.user} />
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>

          <Dialog.Root
            onOpenChange={(open) => {
              if (!open) {
                setOnboardingView("");
              }
            }}
          >
            <Dialog.Trigger asChild>
              <button className="button pill secondary right">
                <h5>Add New</h5>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="modal-overlay" />

              {onboardingView === "" ? (
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>Add new Individual App</h3>
                    </div>
                  </div>
                  <Accordion.Root className="accordion-component" type="single" defaultValue="item-1" collapsible>
                    <Accordion.Item className="accordion-item" value="item-1">
                      <Accordion.AccordionTrigger className="accordion-trigger">
                        <h4>Add Attic Managed App</h4>
                        <IoIosArrowDown style={{ marginRight: 10 }} size={20} className={"accordion-chevron"} />
                      </Accordion.AccordionTrigger>
                      <Accordion.AccordionContent className="accordion-content">
                        <div className="title-subtitle-cont">
                          <p>Automatically create accounts, and manage or delete them via our dashboard.</p>
                        </div>
                        <div className="app-icon-button-grid-cont">
                          {Object.keys(newSupportedServices).map((slug: string) => {
                            return (
                              <div className="app-icon-button-cont" onClick={() => setOnboardingView(slug)}>
                                <img src={`/assets/app-icons/${slug}.png`} className="app-icon" />
                                <p>{newSupportedServices[slug].name}</p>
                              </div>
                            );
                          })}
                          {Object.keys(newSupportedServices).length === 0 && <p>User already has all managed apps</p>}
                        </div>
                      </Accordion.AccordionContent>
                    </Accordion.Item>
                    <div style={{height:8}}></div>
                    <Accordion.Item className="accordion-item" value="item-2">
                      <Accordion.AccordionTrigger className="accordion-trigger">
                        <h4>Add App Manually</h4>
                        <IoIosArrowDown style={{ marginRight: 10 }} size={20} className={"accordion-chevron"} />
                      </Accordion.AccordionTrigger>
                      <Accordion.AccordionContent className="accordion-content">
                        <p style={{ marginBottom: 16 }}>
                          Here you can securely store unique login details for each account they will be using.
                        </p>
                        <AddUnmanagedIndividualAccount updateUserData={props.updateUserData} user={props.user} />
                      </Accordion.AccordionContent>
                    </Accordion.Item>
                  </Accordion.Root>
                </Dialog.Content>
              ) : (
                <Dialog.Content className="modal-cont">
                  <AccountOnboardingForm
                    slug={onboardingView}
                    updateUserData={props.updateUserData}
                    returnFunction={() => setOnboardingView("")}
                    user={props.user}
                  />
                </Dialog.Content>
              )}
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </div>
      <div className="card-container">{appCards}</div>
    </div>
  );
}

export default PersonalAccountsModule;
