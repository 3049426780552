import { useEffect, useState } from "react";
import LoadingButton from "./loadingButton";
import { SERVER_URL } from "../../App";
import { useNavigate } from "react-router-dom";

function ChangeSharedAccountAccess(props: {
  user: any;
  appName: string;
  updateUserData: () => void;
  sharedAppDetails: any;
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [view, setView] = useState<"main" | "revoke" | "adminDelete" | "delete">("main");
  const navigate = useNavigate();

  useEffect(() => {
    if (props.sharedAppDetails.accountUsers.admin.includes(props.user.email)) {
      setView("adminDelete");
    }
  }, [props.sharedAppDetails]);

  async function revokeAccount() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/RevokeSharedAccountsAccess";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        appNames: [props.appName],
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=RevokeSharedAccountsAccess&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=RevokeSharedAccountsAccess");
    }
  }

  async function deleteAccount() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/DeleteSharedAccounts";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        apps: [props.appName],
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=DeleteSharedApps&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=DeleteSharedApps");
    }
  }

  if (view === "revoke") {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Change Access</h4>
          <button
            onClick={() => {
              setView("main");
            }}
            className="button pill secondary right"
          >
            <h5>Cancel</h5>
          </button>
        </div>
        <p style={{ textAlign: "center", color: "#9e3120" }}>{props.user.firstName} will no longer have access.</p>
        <LoadingButton text="Revoke Access" onClick={revokeAccount} singleUse={true} warning={true} />
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  } else if (view === "delete") {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Delete Shared Account</h4>
          <button
            onClick={() => {
              setView("adminDelete");
            }}
            className="button pill secondary right"
          >
            <h5>Cancel</h5>
          </button>
        </div>
        <p style={{ textAlign: "center", color: "#9e3120" }}>This cannot be undone</p>
        <LoadingButton text="Delete" onClick={deleteAccount} singleUse={true} warning={true} />
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  } else if (view === "adminDelete") {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Delete Shared Account</h4>
          <div className="button-cont row">
            <button onClick={() => setView("delete")} className="button pill secondary">
              <h5>Delete</h5>
            </button>
          </div>
        </div>
        <p>
          Deleting the shared details for {props.appName} will cause the details to be permanently lost and all users
          will loose access.
        </p>
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="module-component">
        <div className="module-component-header">
          <h4>Change Access</h4>
          <div className="button-cont row">
            <button onClick={() => setView("revoke")} className="button pill secondary">
              <h5>Revoke</h5>
            </button>
          </div>
        </div>
        <p>
          When revoking {props.user.firstName}'s access, all the other users of {props.appName} won't be effected.
        </p>
        <div hidden={errorMessage === ""}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    );
  }
}

export default ChangeSharedAccountAccess;
