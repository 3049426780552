/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown, IoIosClose, IoIosAdd } from "react-icons/io";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import LoadingScreen from "./loadingScreen";
import { SERVER_URL } from "../../App";
import { Link, useNavigate } from "react-router-dom";


interface EditSharedAppsProps {
  sharedApps: Array<string>;
  setSharedApps: Function;
  startingApps?: Array<string>;
}
function EditSharedApps(props: EditSharedAppsProps) {
  const [allSharedApps, setAllSharedApps] = useState([]);
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [addedAppTags, setAddedAppTags] = useState<JSX.Element[]>([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();


  // This function fetches a list of shared apps from the server - run once on load
  async function getSharedApps() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/GetUsersSharedAccounts";

    // Get the token and manager email from local storage
    const token = localStorage.getItem("token");

    // Create an empty array to store the shared apps
    const sharedApps: any = [];

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([
        responsePromise,
        timeoutPromise,
      ]);



      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=getSharedApps&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error("Error"));
      
      } else {
        
        return await response.json().then((data:any) => {
          if (!data["success"]) {
            navigate("/login?trigger=getSharedApps&error=true&responseStatus=" + response.status + "&msg=" + data["msg"]);
          } else {
            Object.keys(data["msg"]).map(function (name, index) {
              sharedApps.push(name);
            });
          }
        })

        // Prefill existing apps
        .then(async function () {
          if (props.startingApps != undefined) {
            await setSelectedApps(props.startingApps);
          }
        })

        // Update the state with the list of shared apps and populate the dropdown with the list
        .then(async function () {
          console.log(sharedApps);
          await setAllSharedApps(sharedApps);
        })
      }
    
    } catch (error) {
      console.log(error);
      // Redirect to login
      navigate("/login?error=true&responseStatus=408&trigger=getSharedApps");
    }
  }

  useEffect(() => {
    getSharedApps().then(() => {
      setLoading(false);
    });
  }, []);

  async function loadSelectedApps(selectedApps: Array<string>) {
    const addedAppsCont: any = [];
    await selectedApps.forEach(async function (name) {
      addedAppsCont.push(
        <div className="edit-app-tag" onClick={() => removeApp(name)}>
          <p>{name}</p>
          <IoIosClose style={{ margin: "auto" }} size={22} />
        </div>,
      );
    });
    await setAddedAppTags(addedAppsCont);
    await fillDropdown(allSharedApps);
  }
  useEffect(() => {
    loadSelectedApps(selectedApps);
  }, [selectedApps, allSharedApps]);

  // This function creates a list of dropdown items from an array of strings and updates the state with the data
  async function fillDropdown(items: Array<string>) {
    // Create an empty array to store the dropdown items
    const dropdownItems: any = [];

    // Get the shared apps from the props
    const result = selectedApps;

    // Loop through each item and create a dropdown item for it if it is not already in the shared apps list
    await items.map(async function (name, index) {
      if (!result.includes(name)) {
        await dropdownItems.push(
          <div className="dropdown-item" onClick={() => addApp(name)}>
            <IoIosAdd style={{ marginRight: 10 }} size={22} />
            <h5>{name}</h5>
          </div>,
        );
      }
    });

    // Update the state with the list of dropdown items
    setDropdownItems(dropdownItems);
    return true;
  }

  async function addApp(app: string) {
    setSearchQuery("");

    const newApps = [...selectedApps, app];

    await props.setSharedApps(newApps);
    await setSelectedApps(newApps);
  }

  function removeApp(app: any) {
    const updatedApps = selectedApps.filter((e) => e !== app);
    props.setSharedApps(updatedApps);
    setSelectedApps(updatedApps);
  }

  // Function to refine shared apps based on search query
  async function searchSharedApps(query: string) {
    const matchingApps: any = [];
    await allSharedApps.map(async function (name: string, index: number) {
      if (name.toLowerCase().includes(query.toLowerCase())) {
        matchingApps.push(name);
      }
    });
    await fillDropdown(matchingApps);
  }

  // Use effect for clicking outside of open drop down to close it
  useEffect(() => {
    // add event listener to listen for clicks outside of the dropdown-container div
    const handleOutsideClick = (event: any) => {
      if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
        setDropdownVisibility(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    // remove event listener on component unmount
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownContainerRef]);

  return (
    <div>
      <div hidden={!loading}>
        <LoadingScreen />
      </div>
      <div ref={dropdownContainerRef} className="dropdown-container">
        <div className="dropdown-search-container">
          <input
            tabIndex={-1}
            type="text"
            autoComplete="off"
            placeholder="Search your apps"
            id="myInput"
            className="dropdown-search"
            onChange={(event) => {
              searchSharedApps(event.target.value);
              setSearchQuery(event.target.value);
            }}
            onFocus={() => setDropdownVisibility(true)}
            value={searchQuery}
          />
          <IoIosArrowDown
            style={{ margin: "auto" }}
            size={22}
            onClick={() => setDropdownVisibility(!dropdownVisibility)}
          />
        </div>

        <div className="dropdown-items-container" hidden={!dropdownVisibility || dropdownItems.length == 0}>
          {dropdownItems}
        </div>
      </div>

      {addedAppTags.length == 0 ? (
        <div className="add-apps-container empty">
          <p>Search or open the dropdown to add apps.</p>
        </div>
      ) : (
        <div className="add-apps-container">{addedAppTags}</div>
      )}
    </div>
  );
}

export default EditSharedApps;
