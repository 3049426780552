import React, { useState } from "react";
import "../../App.css";
import MoonLoader from "react-spinners/MoonLoader";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

interface LoadingButtonProps {
  text: string;
  id?: string;
  onClick: () => Promise<void>;
  singleUse?: boolean;
  warning?: boolean;
}

function LoadingButton(props: LoadingButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const handleClick = () => {
    if (!isComplete) {
      setIsLoading(true);
      props
        .onClick()
        .then(() => {
          setIsLoading(false);
          if (props.singleUse) {
            setIsComplete(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <button
      className="submit-button"
      id={props.id}
      style={props.warning ? { backgroundColor: "#9E3120", borderColor: "#9E3120" } : {}}
      onClick={handleClick}
      disabled={isComplete}
    >
      {isLoading ? (
        <MoonLoader
          color="#ffffff"
          cssOverride={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          loading
          size={20}
          speedMultiplier={0.75}
        />
      ) : isComplete ? (
        <IoIosCheckmarkCircleOutline style={{ marginRight: 10, color: "#ffffff" }} size={25} />
      ) : (
        <h4 className="button-txt">{props.text}</h4>
      )}
    </button>
  );
}

export default LoadingButton;
