/* eslint-disable guard-for-in */
import React, { useEffect, useState } from "react";
import Navbar from "../components/atoms/navbar";
import OrganisationDetailsModule from "../components/organisms/organisationDetailsModule";
import SubordinatesModule from "../components/organisms/subordinatesModule";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "../components/atoms/loadingScreen";
import UserDetail from "./userDetail";
import { SERVER_URL } from "../App";
import SubordnateDashboard from "./subordinateDashboard";

function Dashboard() {
  const [users, setUsers] = useState<{ [key: string]: any }>({});
  const [orgData, setOrgData] = useState({});
  const [managedServices, setManagedServices] = useState({});
  const [selectedUser, setSelectedUser] = useState("");
  const [currentUser, setCurrentUser] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(true);
  const [isManager, setIsManager] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Gets user data on first loads
  useEffect(() => {
    getUserData();
  }, []);

  // Update page if a new user is
  // useEffect(() => {
  //   if (selectedUser !== "") {
  //     setSearchParams({ u: Math.max(Object.keys(users).indexOf(selectedUser), 0).toString() });
  //   } else {
  //     setSearchParams({});
  //   }
  // }, [selectedUser]);

  function setUser(user: string, allUsers = users) {
    if (user !== "") {
      setSearchParams({ u: Math.max(Object.keys(allUsers).indexOf(user), 0).toString() });
    } else {
      setSearchParams({});
    }
    setSelectedUser(user);
  }

  // Collects user's and subordinates data froms server
  async function getUserData(toHome?: boolean) {
    setLoading(true);

    // If the action wants the user to return home
    if (toHome) {
      setUser("");
    }

    const url = SERVER_URL + "/dashboard/collectDashboardData";
    const token = localStorage.getItem("token");

    if (!token) {
      return navigate("/login");
    }

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=getUserData&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        console.log(data);
        navigate("/login?trigger=getUserData&error=true&responseStatus=" + response.status + "&msg=" + data["msg"]);
      } else {
        setCurrentUser(data["data"]["currentUser"]);
        console.log(data["data"]["currentUser"]);

        if (data["data"]["currentUser"].email.split("@")[1] === "chant.dev") {
          localStorage.setItem("demo-account", "true");
        } else {
          localStorage.removeItem("demo-account");
        }

        if (
          data["data"]["currentUser"]["accountType"] === "manager" ||
          data["data"]["currentUser"]["accountType"] === "superadmin"
        ) {
          const urlSelectedUser = searchParams.get("u");
          const resUsers = data["data"]["users"];
          setUsers(resUsers);
          // If a page is reloading, see if a user had been open

          console.log(searchParams, urlSelectedUser, resUsers);
          if (urlSelectedUser && Object.keys(resUsers)[parseInt(urlSelectedUser)]) {
            setUser(Object.keys(resUsers)[parseInt(urlSelectedUser)], resUsers);
          } else {
            setUser("");
          }
          // Set collected data as states
          setOrgData(data["data"]["organisation"]);
          setManagedServices(data["data"]["managedServices"]);
          const managedServicesString = await JSON.stringify(data["data"]["managedServices"]);
          localStorage.setItem("managedServicesString", managedServicesString);
        }
        const supportedServicesString = await JSON.stringify(data["data"]["supportedServices"]);
        localStorage.setItem("supportedServicesString", supportedServicesString);
        setLoading(false);

        return true;
      }
    } catch (error) {
      // Redirect to login
      navigate("/login?error=true&responseStatus=408&trigger=getUserData");
    }
  }

  // Return loading if not ready, return dispay if fetch and render is complete
  if (loading) {
    return <LoadingScreen />;
  } else if (currentUser.accountType == "superadmin" || currentUser.accountType == "admin") {
    if (selectedUser !== "") {
      return (
        <UserDetail
          currentUser={currentUser.email === users[selectedUser].email}
          users={users}
          selectedUser={selectedUser}
          updateUserData={getUserData}
          returnFunction={() => setUser("")}
          managedServices={managedServices}
        />
      );
    } else {
      return (
        <div className="page-container">
          <Navbar authedOnly={false} />
          <div className="full-page-element">
            <h2 style={{ marginBottom: 8 }}>Welcome to your Attic, {currentUser.firstName}</h2>
            <div className="module-container">
              <OrganisationDetailsModule orgData={orgData} managedServices={managedServices} />
              <SubordinatesModule users={users} setSelectedUser={setUser} />
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <SubordnateDashboard user={currentUser} managedServices={managedServices} updateUserData={getUserData} />;
  }
}

export default Dashboard;
