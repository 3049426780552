import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Accordion from "@radix-ui/react-accordion";
import { IoIosArrowDown } from "react-icons/io";
import AccountOnboardingForm from "../molecules/accountOnboardingForm";
import AddUnmanagedIndividualAccount from "../molecules/addUnmanagedIndividualAccount";
import DeleteIndividualAccount from "../molecules/deleteIndividualAppsForm";
import AppCard from "../molecules/indAppCard";

interface SubordinatePersonalAccountsModuleProps {
  user: any;
}

function SubordinatePersonalAccountsModule(props: SubordinatePersonalAccountsModuleProps) {
  const [appCards, setAppCards] = useState([]);

  useEffect(() => {
    fillApps().then((appCards) => {
      setAppCards(appCards);
    });
  }, []);

  async function fillApps() {
    const supportedServicesString: any = localStorage.getItem("supportedServicesString");
    const supportedServices = JSON.parse(supportedServicesString);
    const appCards: any = [];

    for (const name in props.user.individualLogins) {
      const appInstance = props.user.individualLogins[name];
      const serviceDetail = supportedServices[appInstance.slug];

      await appCards.push(
        <AppCard name={name} lastUsed={props.user.individualLogins[name].lastLogin} serviceDetail={serviceDetail} />,
      );
    }
    return appCards;
  }

  return (
    <div className="half-height-module">
      <div className="blur_div" hidden={props.user.twoFAEmail !== undefined}></div>
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">Individual Accounts</h3>
          <p>The accounts that only {props.user.firstName} uses.</p>
        </div>
        <div></div>
      </div>
      <div className="card-container">{appCards}</div>
    </div>
  );
}

export default SubordinatePersonalAccountsModule;
