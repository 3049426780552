import React, { useEffect, useState } from "react";
import Navbar from "../components/atoms/navbar";
import LoginForm from "../components/molecules/loginForm";
import { useSearchParams } from "react-router-dom";

function Login() {
  const [showError, setShowError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorBody, setErrorBody] = useState("");
  const [errorTrigger, setErrorTrigger] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // Runs display errors on load
  useEffect(() => {
    displayErrors();
    localStorage.removeItem("demo-account");
  }, []);

  // Function to render the correct error message
  function displayErrors() {

    // Get parametirs containing error information
    const error = searchParams.get("error");
    const responseStatus = searchParams.get("responseStatus");
    const msg = searchParams.get("msg");
    const trigger = searchParams.get("trigger");

    // If an error has occured
    if (error === "true") {
      if (responseStatus === "410" || responseStatus === "500") {
        setErrorTitle("An error (" + responseStatus + ") has occured. Sign-in to restart");
        setErrorBody("The server has crashed and your secure session details were lost, please sign in to continue. This demo is alpha, so can be unstable - it was last updated on 25/04/2023.");
      } else if (responseStatus === "408") {
        setErrorTitle("Your request timed out");
        setErrorBody("This demo is hosted on low bandwidth servers in the Republic of Ireland. Connection speeds are limited, thus it may timeout on certian requests.");
      } else if (responseStatus === "403") {
        setErrorTitle("Your token has expired");
        setErrorBody("Please re-enter your login details to continue.");
      } else if (msg !== null) {
        setErrorTitle("An error has occured - " + responseStatus);
        setErrorBody(msg);
      } else {
        setErrorTitle("An unknown error has occured. Sign-in to restart");
        setErrorBody("The server has crashed and your secure session details were lost, please sign in to continue. This demo is alpha, so can be unstable - it was last updated on 25/04/2023.");
      }
      if (trigger !== null) {
        setErrorTrigger(trigger);
      }
      setShowError(true);
    }
  }
  
  return (
    <div className="page-container">
      <Navbar authedOnly={false} logoOnly={true}/>
      <div className="center-page-element">
        <LoginForm />
        <div style={{width: 450, margin: 16}} hidden={!(showError)}>
          <div className="red-text-cont">
            <h3>{errorTitle}</h3>
            <p>{errorBody}</p>
            <p>Trigger - {errorTrigger}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
