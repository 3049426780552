import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../App";
import * as Dialog from "@radix-ui/react-dialog";
import LoadingButton from "../atoms/loadingButton";
import EditSharedApps from "../atoms/editSharedApps";
import AddSharedAccountForm from "../molecules/addSharedAccountForm";
import DeleteSharedAccount from "../molecules/deleteSharedAppsForm";
import SharedAppCardandModal from "../molecules/sharedAppCard&Modal";
import SharedAppCard from "../molecules/sharedAppCard";

interface SubordinateSharedAccountsModuleProps {
  user: any;
}

function SubordinateSharedAccountsModule(props: SubordinateSharedAccountsModuleProps) {
  const [appCards, setAppTags] = useState([]);

  useEffect(() => {
    fillApps();
  }, []);

  async function fillApps() {
    const sharedLogins: any = [];
    const appCards: any = [];
    await Object.keys(props.user.sharedLogins).map(async function (name, index) {
      await appCards.push(
        <SharedAppCard user={props.user} name={name} lastUsed={props.user.sharedLogins[name].lastLogin} />,
      );
      await sharedLogins.push(name);
    });
    setAppTags(appCards);
    return sharedLogins;
  }

  return (
    <div className="half-height-module">
      <div className="blur_div" hidden={props.user.twoFAEmail !== undefined}></div>
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">Shared Accounts</h3>
          <p>
            The accounts {props.user.firstName} shares with the {props.user.companyName} team.
          </p>
        </div>
      </div>
      <div className="card-container">{appCards}</div>
    </div>
  );
}

export default SubordinateSharedAccountsModule;
