import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../App";
import * as Dialog from "@radix-ui/react-dialog";
import LoadingButton from "../atoms/loadingButton";
import EditSharedApps from "../atoms/editSharedApps";
import AddSharedAccountForm from "../molecules/addSharedAccountForm";
import DeleteSharedAccount from "../molecules/deleteSharedAppsForm";
import SharedAppCardandModal from "../molecules/sharedAppCard&Modal";

interface SharedAccountsModuleProps {
  user: any;
  allUsers?: any;
  currentUser: boolean;
  updateUserData: () => void;
}

function SharedAccountsModule(props: SharedAccountsModuleProps) {
  const [sharedApps, setSharedApps] = useState([]);
  const [appCards, setAppTags] = useState([]);
  const [editAppsModule, setEditAppsModule] = useState(<div></div>);
  const [addAppModule, setAddAppModule] = useState(<div></div>);
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    fillApps().then((sharedLogins) => {
      setEditAppsModule(
        <EditSharedApps startingApps={sharedLogins} sharedApps={sharedApps} setSharedApps={setSharedApps} />,
      );
    });
  }, [props.user]);

  useEffect(() => {
    if ((props.user.accountType === "superadmin" || props.user.accountType === "admin") && props.allUsers) {
      setAddAppModule(
        <AddSharedAccountForm updateUserData={props.updateUserData} allUsers={props.allUsers} user={props.user} />,
      );
    }
  }, [props.user]);

  async function fillApps() {
    const sharedLogins: any = [];
    const appCards: any = [];
    await Object.keys(props.user.sharedLogins).map(async function (name, index) {
      await appCards.push(
        <SharedAppCardandModal
          user={props.user}
          updateUserData={props.updateUserData}
          name={name}
          lastUsed={props.user.sharedLogins[name].lastLogin}
        />,
      );
      await sharedLogins.push(name);
    });
    setSharedApps(sharedLogins);
    setAppTags(appCards);
    return sharedLogins;
  }

  async function submitForm() {
    const url = SERVER_URL + "/dashboard/EditSharedAccountAccess";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 25000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        apps: sharedApps,
        givenUserEmail: props.user.email,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        localStorage.clear();
        navigate("/login?trigger=confirmNewSharedApps&error=true&responseStatus=" + response.status);
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setErrorMessage(data["msg"]);
        return Promise.reject(new Error(data.msg));
      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=editSharedAppsSubmit");
    }
  }

  return (
    <div className="half-height-module">
      <div className="blur_div" hidden={props.user.twoFAEmail !== undefined}></div>
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">Shared Accounts</h3>
          <p>
            The accounts {props.user.firstName} shares with the {props.user.companyName} team.
          </p>
        </div>
        {props.currentUser ? (
          <div className="button-cont row">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="button pill secondary right">
                  <h5>Remove</h5>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="modal-overlay" />
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>Delete Shared Apps You Manage</h3>
                      <p>By adding these apps, all the people with access will lose it.</p>
                    </div>
                  </div>
                  <DeleteSharedAccount updateUserData={props.updateUserData} user={props.user} />
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="button pill secondary right">
                  <h5>Add New</h5>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="modal-overlay" />
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>Store a New Shared App</h3>
                      <p>
                        By adding these apps, {props.user.firstName} will get one-click access like any other employee.
                      </p>
                    </div>
                  </div>
                  {addAppModule}
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        ) : (
          <div className="button-cont row">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="button pill secondary right">
                  <h5>Edit Access</h5>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="modal-overlay" />
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>Add or remove shared apps</h3>
                      <p>
                        By adding these apps, {props.user.firstName} will get one-click access like any other employee.
                        Updating them may take a few seconds.
                      </p>
                    </div>
                  </div>
                  {editAppsModule}
                  <LoadingButton text="Confirm" singleUse={true} onClick={submitForm} />
                  {errorMessage ? (
                    <p className="error-txt" style={{ marginTop: 16 }}>
                      {errorMessage}
                    </p>
                  ) : null}
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        )}
      </div>
      <div className="card-container">{appCards}</div>
    </div>
  );
}

export default SharedAccountsModule;
