import React from "react";
import Navbar from "../components/atoms/navbar";
import NewTeamMemberForm from "../components/molecules/newTeamMemberForm";

function AddTeamMember() {
  return (
    <div className="page-container">
      <Navbar authedOnly={true}/>
      <a style={{marginLeft: 24}} href="/">{"<"} Back to dashboard</a>
      <div className="center-page-element">
        <NewTeamMemberForm />
      </div>
    </div>
  );
}

export default AddTeamMember;
