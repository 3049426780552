import React from "react";
import Navbar from "../components/atoms/navbar";
import CompanyRegistrationForm from "../components/molecules/companyRegistrationForm";

function CompanyRegistration() {
  return (
    <div className="page-container">
      <Navbar authedOnly={false}/>
      <div hidden={false}>
        <div className="full-page-element row" hidden={true}>
          <div className="half-column">
            <div className="center-page-element content-container">
              <h2 className="title-txt">Welcome to flawless business computing.</h2>
              <h4>Seamlessly access apps and services, while only logging in once per day.
                Instantly share accounts between team members, while sustaining incredible
                security. This is big business IT, with startup speed and pricing.
              </h4>
            </div>
          </div>
          <div className="half-column">
            <div className="center-page-element">
              <CompanyRegistrationForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyRegistration;
