/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../atoms/loadingButton";
import * as Checkbox from "@radix-ui/react-checkbox";
import { IoIosCheckmark } from "react-icons/io";
import "../../App.css";
import { SERVER_URL } from "../../App";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";

interface AddSharedAccountFormProps {
  user: any;
  allUsers: any;
  updateUserData: () => void;
}

function AddSharedAccountForm(props: AddSharedAccountFormProps) {
  const [accountUsername, setAccountUsername] = useState(props.user.email);
  const [accountPassword, setAccountPassword] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountHostname, setAccountHostname] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userChecks, setUserChecks] = useState();
  const [passwordHidden, setPasswordHidden] = useState(true);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  async function findUsers() {
    const users: any = [];
    await Object.keys(props.allUsers).map(function (user: any, index: number) {
      if (user != props.user.email) {
        users.push(
          <div className="check-cont">
            <Checkbox.Root className="checkbox-root" id={user}>
              <Checkbox.Indicator className="checkbox-indicator">
                <IoIosCheckmark size={20} />
              </Checkbox.Indicator>
            </Checkbox.Root>
            <label className="Label" htmlFor={user}>
              <p>{props.allUsers[user].firstName + " " + props.allUsers[user].surname}</p>
            </label>
          </div>,
        );
        setUserChecks(users);
      }
    });
  }

  useEffect(() => {
    findUsers();
  }, [props.allUsers]);

  async function getCheckedUsers(): Promise<string[]> {
    const checkboxes = Array.from(document.querySelectorAll(".checkbox-root"));
    const checkedUsers = [];
    for (const checkbox of checkboxes) {
      if (checkbox.getAttribute("data-state") === "checked") {
        checkedUsers.push(checkbox.id);
      }
    }
    return checkedUsers;
  }


  async function submitForm() {
    const url = SERVER_URL + "/dashboard/StoreSharedAccount";

    const checkedUsers = await getCheckedUsers();

    // All fields must have data
    if (accountUsername === "" || accountPassword === "" || accountName === "" || accountHostname === "") {
      setErrorMessage("No fields can be blank");
      return Promise.reject(new Error("No fields can be blank."));
    }

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        accountName: accountName,
        accountHostname: accountHostname,
        accountUsername: accountUsername,
        accountPassword: accountPassword,
        addedUsers: checkedUsers,
      }),
    })

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([
        responsePromise,
        timeoutPromise,
      ]);

      // Decode response data
      const data = await response.json();
      console.log(data);
      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=addSharedAccountsForm&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));

      } else if (!data["success"]) {
        setErrorMessage(data.msg);
        return Promise.reject(new Error(data.msg));

      } else {
        props.updateUserData();
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=addSharedAccountsForm");
    }
  }

  return (
    <div className="modal-body">
      <div className="modal-section">
        <h4>Account Details</h4>
        <div className="form-cont wide">
          <input
            type="text"
            autoComplete="off"
            autoCapitalize="off"
            placeholder="Account Name"
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-shared-btn")?.click()}
            onChange={(event) => setAccountName(event.target.value)}
            value={accountName}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Account Hostname"
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-shared-btn")?.click()}
            autoCapitalize="off"
            onChange={(event) => setAccountHostname(event.target.value)}
            value={accountHostname}
          />
          <input
            type="text"
            autoComplete="off"
            autoCapitalize="off"
            placeholder="Account Username"
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-shared-btn")?.click()}
            onChange={(event) => setAccountUsername(event.target.value)}
            value={accountUsername}
          />
          <div style={{ position: "relative" }}>
            <input
              type={passwordHidden ? "password" : "text"}
              autoComplete="off"
              placeholder="Password"
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("add-shared-btn")?.click()}
              onChange={(event) => setAccountPassword(event.target.value)}
              value={accountPassword}
            />
            {passwordHidden ? (
              <HiOutlineEye onClick={() => setPasswordHidden(false)} className="password-icon" size={20} />
            ) : (
              <HiOutlineEyeSlash onClick={() => setPasswordHidden(true)} className="password-icon" size={20} />
            )}
          </div>
        </div>
      </div>
      <div className="modal-section">
        <h4>Add Users</h4>
        <div className="column-cont">{userChecks}</div>
      </div>
      <div className="green-text-cont">
        <h4>
          This account will be managed by {props.user.firstName} {props.user.surname}. Change selected user to change
          ownership.
        </h4>
      </div>
      <LoadingButton id="add-shared-btn" text={"Add Account"} singleUse={true} onClick={submitForm} />
      <p className="error-txt">{errorMessage}</p>
    </div>
  );
}

export default AddSharedAccountForm;
