import { IoTime } from "react-icons/io5";

interface AppCardProps {
  name: string;
  lastUsed: string;
  serviceDetail?: any;
}

function AppCard(props: AppCardProps) {
  function getRelativeTime(timestamp: any) {
    if (timestamp === "Never") {
      return "Never used";
    }
    const millisecondsAgo = Date.now() - timestamp;
    const secondsAgo = Math.floor(millisecondsAgo / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    const weeksAgo = Math.floor(daysAgo / 7);
    const monthsAgo = Math.floor(daysAgo / 30);

    if (hoursAgo < 24) {
      return `Used Today`;
    } else if (daysAgo < 7) {
      switch (daysAgo) {
        case 1:
          return "Used Yesterday";
        default:
          return `Used ${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
      }
    } else if (weeksAgo < 4) {
      return `Used ${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
    } else {
      return `Used ${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
    }
  }

  return (
    <div className="app-card" key={props.name}>
      <div className={"card-header"}>
        {props.serviceDetail ? (
          <img className="app-icon small" src={props.serviceDetail.icon192}></img>
        ) : (
          <div className="app-icon small placeholder">{props.name[0]}</div>
        )}
        <h4>{props.name}</h4>
      </div>
      <div className="icon-text-cont">
        <IoTime />
        <p>{getRelativeTime(props.lastUsed)}</p>
      </div>
    </div>
  );
}

export default AppCard;
