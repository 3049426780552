import React from "react";
import Navbar from "../../components/atoms/navbar";
import RequestPasswordResetForm from "../../components/molecules/passwords/requestPasswordResetForm";

function RequestPasswordReset() {
  return (
    <div className="page-container">
      <Navbar authedOnly={false}/>
      <div className="center-page-element">
        <RequestPasswordResetForm />
      </div>
    </div>
  );
}

export default RequestPasswordReset;
