/* eslint-disable guard-for-in */
/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../atoms/loadingButton";
import * as Dialog from "@radix-ui/react-dialog";
import * as Toast from "@radix-ui/react-toast";
import { SERVER_URL } from "../../App";

interface UserDetailsModuleProps {
  user: any;
  currentUser: boolean;
  updateUserData: (toHome?: boolean) => void;
  manager: boolean;
}

function UserDetailsModule(props: UserDetailsModuleProps) {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [twoFAEmail, set2FAEmail] = useState("");
  const [apps, setApps] = useState<Record<string, JSX.Element[]>>({ managed: [], normal: [] });
  const [encryptionKey, setEncryptionKey] = useState("Click to Reveal");
  const [deleteUserError, setDeleteUserError] = useState("");
  const [updateUserDataError, setUpdateUserDataError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    set2FAEmail(props.user.twoFAEmail);
    setFirstName(props.user.firstName);
    setSurname(props.user.surname);

    getAppsToDelete();
  }, [props.user]);

  let userDescription = "";
  if (props.user.accountType === "superadmin") {
    userDescription =
      props.user.companyName +
      "'s account controller, managing " +
      Object.keys(props.user.subordinates).length +
      " team member(s).";
  } else if (props.user.accountType === "admin") {
    userDescription =
      "A Team Repair admin, managing " + Object.keys(props.user.subordinates).length + " team member(s).";
  } else {
    userDescription = "" + props.user.companyName + " team member, managed by " + props.user.manager;
  }

  async function getEncyptionKey() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/GetEncryptionKey";

    // Get the token from local storage
    const token: any = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=emailEncryptionKey&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setEncryptionKey("Error collecting key.");
        return Promise.reject(new Error(data["msg"]));
      } else {
        setEncryptionKey(data["key"]);
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=emailEncryptionKey");
    }
  }

  async function emailEncryptionKey() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/EmailEncryptionKey";

    // Get the token from local storage
    const token: any = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=emailEncryptionKey&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        return Promise.reject(new Error(data["msg"]));
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=emailEncryptionKey");
    }
  }

  async function updateUserData() {
    const url = SERVER_URL + "/atticaccounts/UpdateUserData";

    // Get the token from local storage
    const token: any = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        firstName: firstName,
        surname: surname,
        twoFAEmail: twoFAEmail,
        givenUser: props.user.email,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=updateUserData&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setUpdateUserDataError(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        setUpdateUserDataError("");
        props.updateUserData(true);
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=updateUserData");
    }
  }

  async function deleteUser() {
    // Construct the URL to fetch data from the server
    const url = SERVER_URL + "/dashboard/deleteUser";

    // Get the token from local storage
    const token: any = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        givenUser: props.user.email,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();

      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=deleteUser&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        setDeleteUserError(data["msg"]);
        return Promise.reject(new Error(data["msg"]));
      } else {
        props.updateUserData(true);
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=deleteUser");
    }
  }

  async function getAppsToDelete() {
    const apps: Record<string, JSX.Element[]> = { managed: [], normal: [] };
    for (const app in props.user.individualLogins) {
      const appElement: JSX.Element = <li key={app}>{app}</li>;
      if (props.user.individualLogins[app].atticManaged) {
        await apps["managed"].push(appElement);
      } else {
        await apps["normal"].push(appElement);
      }
    }
    setApps(apps);
  }

  return (
    <div className="twofifth-full-height-module">
      <div className="blur_div" hidden={props.user.twoFAEmail !== undefined}>
        <div className="centered">
          <div>
            <h3>Account setup incomplete</h3>
            <p>User has not responded to their account setup email</p>
          </div>

          <div className="button-cont row">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="button pill secondary right">
                  <h5>Delete Account</h5>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="modal-overlay" />
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>Delete {props.user.firstName}'s Account</h3>
                      <p>
                        By deleting their account, they will no longer be able to sign in and access apps, and the
                        following informaiton will be deleted:
                      </p>
                    </div>
                  </div>

                  <div className="module-component">
                    <h4>Attic Managed Accounts</h4>
                    <p>
                      The following "managed by Attic" accounts will be deactivated, meaning all of their files and
                      content will be lost, and any bills will cease.
                    </p>
                    {apps["managed"].length > 0 ? (
                      <ul>{apps["managed"]}</ul>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <i>User has no managed apps.</i>
                      </p>
                    )}
                  </div>

                  <div className="module-component">
                    <h4>Unmanaged Login Details</h4>
                    <p>
                      The following "unmanaged by Attic" accounts will removed from Attic and the account details will
                      be permanently lost.
                    </p>
                    {apps["normal"].length > 0 ? (
                      <ul>{apps["normal"]}</ul>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <i>User has no unmanaged apps.</i>
                      </p>
                    )}
                  </div>

                  <LoadingButton text="Delete Account" singleUse={true} warning={true} onClick={deleteUser} />
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        </div>
      </div>
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">{props.user.firstName + " " + props.user.surname}</h3>
          <p>{userDescription}</p>
        </div>
      </div>
      <div className="module-component">
        <div className="module-component-header">
          <h4>Account Details</h4>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="button pill secondary right">
                <h5> Edit Details</h5>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="modal-overlay" />
              <Dialog.Content className="modal-cont">
                <div className="modal-header">
                  <div className="title-subtitle-cont">
                    <h3>Edit Attic Details</h3>
                  </div>
                </div>
                <h4>Personal Details</h4>
                <div className="form-cont fill">
                  <div className="half-input-cont">
                    <input
                      className="halfinput"
                      type="text"
                      autoComplete="off"
                      placeholder="First Name"
                      id="FNAME"
                      onKeyDown={(event) => event.key === "Enter" && document.getElementById("update-details")?.click()}
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                    <input
                      className="halfinput"
                      type="text"
                      autoComplete="off"
                      placeholder="Surname"
                      id="SNAME"
                      value={surname}
                      onKeyDown={(event) => event.key === "Enter" && document.getElementById("update-details")?.click()}
                      onChange={(event) => setSurname(event.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <h4>2 Factor Authentication</h4>
                  <p>
                    To maintain security 2 factor authentication is manditory on sign in for Attic. The 2FA email needs
                    to be different to {props.currentUser ? "your" : "then"} work email.
                  </p>
                </div>

                <div className="form-cont fill">
                  <input
                    autoCapitalize="off"
                    onKeyDown={(event) => event.key === "Enter" && document.getElementById("update-details")?.click()}
                    type="text"
                    autoComplete="email"
                    placeholder="Email"
                    id="2FAEmail"
                    value={twoFAEmail}
                    onChange={(event) => set2FAEmail(event.target.value)}
                  />
                  <LoadingButton id="update-details" text="Update" onClick={updateUserData} singleUse={true} />
                  <div hidden={updateUserDataError === ""}>
                    <p className="error-txt">{updateUserDataError}</p>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <p>Email: {props.user.email}</p>
          <p>Organisation: {props.user.companyName}</p>
          <p>Manager: {props.user.manager ? props.user.manager : "None"}</p>
          {props.manager ? <p>Manages: {Object.keys(props.user.subordinates).join(", ")}</p> : null}
          <p>2FA Email: {props.user.twoFAEmail}</p>
        </div>
      </div>

      {(props.user.accountType === "superadmin" || props.user.accountType === "admin") && props.currentUser ? (
        <div className="module-component">
          <div className="module-component-header">
            <h4>Encryption Key</h4>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="button pill secondary right">
                  <h5>Request Copy</h5>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="modal-overlay" />
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>View your Encryption Key</h3>
                    </div>
                  </div>
                  <p>
                    As an admin, if you ever want to reset your password, you will need your Encryption Key. For your
                    security, we can't ever see or access it, so if you lose it, your account may be lost.{" "}
                    <b>You must have a copy of this key securely stored somewhere easily accessable.</b>
                  </p>
                  <div onClick={() => getEncyptionKey()} className="green-text-cont break clickable">
                    <h4>{encryptionKey}</h4>
                  </div>
                  <p>
                    {" "}
                    If you think someone who isn't you has gotten a copy of it, email oliver@useattic to get it updated.
                  </p>
                  <LoadingButton text="Email it to me" singleUse={true} onClick={emailEncryptionKey} />
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
          <p>
            If you lose your Encryption Key you will be unable to change your password, and your entire Attic may be
            lost.
          </p>
        </div>
      ) : null}

      <div className="module-component">
        <div className="module-component-header">
          <h4>Bugs, Requests, & Feedback</h4>
          <a
            href={
              "mailto:patrick@useattic.com?subject=Feedback: " + props.user.email + " from " + props.user.companyName
            }
            onClick={(e: any) => {
              e.preventDefault();
              window.location.href = `mailto:patrick@useattic.com?subject=Feedback: ${props.user.email} from ${props.user.companyName}`;
            }}
          >
            <button className="button pill secondary right">
              <h5>Send Feedback</h5>
            </button>
          </a>
        </div>
        <p>
          If you have found a bug, want a specific feature, or need an account fully integrated hit "Send Feedback".
          Keep it super informal and honest - don't worry about hurting feelings!
        </p>
      </div>

      {!props.currentUser && props.manager ? (
        <div className="module-component">
          <div className="module-component-header">
            <h4>Delete Account</h4>
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <button className="button pill secondary right">
                  <h5>Delete</h5>
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="modal-overlay" />
                <Dialog.Content className="modal-cont">
                  <div className="modal-header">
                    <div className="title-subtitle-cont">
                      <h3>Delete {props.user.firstName}'s Account</h3>
                      <p>
                        By deleting their account, they will no longer be able to sign in and access apps, and the
                        following informaiton will be deleted:
                      </p>
                    </div>
                  </div>

                  <div className="module-component">
                    <h4>Attic Managed Accounts</h4>
                    <p>
                      The following "managed by Attic" accounts will be deactivated, meaning all of their files and
                      content will be lost, and any bills will cease.
                    </p>
                    {apps["managed"].length > 0 ? (
                      <ul>{apps["managed"]}</ul>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <i>User has no managed apps.</i>
                      </p>
                    )}
                  </div>

                  <div className="module-component">
                    <h4>Unmanaged Login Details</h4>
                    <p>
                      The following "managed by Attic" accounts will deactivated, meaning all of their files and content
                      will be lost, and any bills will cease.
                    </p>
                    {apps["normal"].length > 0 ? (
                      <ul>{apps["normal"]}</ul>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <i>User has no unmanaged apps.</i>
                      </p>
                    )}
                  </div>

                  <LoadingButton text="Delete Account" singleUse={true} warning={true} onClick={deleteUser} />
                  <div hidden={deleteUserError === ""}>
                    <p className="error-txt">{deleteUserError}</p>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UserDetailsModule;
