/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import LoadingButton from "../atoms/loadingButton";
import { SERVER_URL } from "../../App";
import Slack from "../atoms/accountUIs/slack";
import Figma from "../atoms/accountUIs/figma";
import GoogleWorkspace from "../atoms/accountUIs/googleWorkspace";
import { triggerOnboarding } from "../../utils/triggerExtension";

interface AccountOnboardingFormProps {
  user: any;
  returnFunction: Function;
  updateUserData: () => void;
  slug: string
}

function AccountOnboardingForm(props: AccountOnboardingFormProps) {
  const [accountConfiguration, setAccountConfiguration] = useState({});
  const [appDetails, setAppDetails] = useState<any>();
  const [orgAppDetails, setOrgAppDetails] = useState<any>();
  const [plan, setPlan] = useState<any>();
  const [price, setPrice] = useState<any>();

  const navigate = useNavigate();

  // Collect details from storage
  useEffect(() => {
    collectServiceDetails();
  }, []);

  useEffect(() => {
    if (orgAppDetails !== undefined) {
      const appUsers = Object.keys(orgAppDetails.users);
      orgAppDetails.users[appUsers[0]]
      setPlan(orgAppDetails.users[appUsers[0]]);
    }
  }, [orgAppDetails]);

  useEffect(() => {
    if (appDetails !== undefined) {
      setPrice(appDetails.plans[orgAppDetails.users[0] || Object.keys(appDetails.plans)[1]]);
    }
  }, [appDetails]);

  useEffect(() => {
    console.log(accountConfiguration)
  }, [accountConfiguration]);



  const appUI:any = {
    "slack": <Slack userEmail={props.user.email} channelEdit={true} userDetails={{ name: props.user.firstName + " " + props.user.surname, email: props.user.email }} setAccountConfiguration={setAccountConfiguration}/>,
    "figma": <Figma userEmail={props.user.email} teamEdit={true} userDetails={{ firstName: props.user.firstName, surname: props.user.surname, email: props.user.email }} setAccountConfiguration={setAccountConfiguration}/>,
    "googleworkspace": <GoogleWorkspace userEmail={props.user.email} userDetails={{ firstName: props.user.firstName, surname: props.user.surname, email: props.user.email }} setAccountConfiguration={setAccountConfiguration}/>
  }

  async function collectServiceDetails() {
    const supportedServicesString:any = await localStorage.getItem('supportedServicesString');
    const supportedServices = await JSON.parse(supportedServicesString);
    const managedServicesString:any = await localStorage.getItem('managedServicesString');
    const managedServices = await JSON.parse(managedServicesString);
    setAppDetails(supportedServices[props.slug]);
    setOrgAppDetails(managedServices[props.slug]);
    return true
  }

  async function submitForm() {
      const url = SERVER_URL + "/dashboard/AddManagedIndividualAccount";
      const token = localStorage.getItem("token");
      const managerEmail = localStorage.getItem("email");

      // Set server timeout
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 20000);
      });

      // Get data
      const responsePromise = fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          accountPlan: orgAppDetails.users[0] || Object.keys(appDetails.plans)[1],
          accountConfiguration: accountConfiguration,
          user: props.user.email,
          accountSlug: orgAppDetails.slug,
        }),
      });

      try {
        // Sets race between timeout and responses
        const response: any = await Promise.race([responsePromise, timeoutPromise]);

        // Decode response data
        const data = await response.json();
        // If the error is due to auth, encrpytion keys, or a server crash
        if (response.status === 403 || response.status === 410 || response.status === 500) {
          navigate("/login?trigger=AccountOnboardingForm&error=true&responseStatus=" + response.status);
          localStorage.clear();
          return Promise.reject(new Error(data.msg));
        } else if (!data["success"]) {
          return Promise.reject(new Error(data.msg));
        } else {
          if (props.user.email != managerEmail) {
            await triggerOnboarding(props.user.email, [appDetails.slug]);
          }
          props.updateUserData();
          return Promise.resolve();
        }
      } catch (error) {
        // Redirect to login
        console.log(error);
        navigate("/login?error=true&responseStatus=408&trigger=addSharedAccountsForm");
      }
  }

  return appDetails ? (
    <div className="modal-body">
      <a className="back-button" onClick={() => props.returnFunction()}>
        {"<"} Back
      </a>
      <div className="title-subtitle">
        <h3>
          Create {props.user.firstName}'s {appDetails.name} Account
        </h3>
        <p>This service will automatically create a {appDetails.name} account for this user.</p>
      </div>
      {appUI[appDetails.slug]}
      <div className="modal-footer">
        <div className="title-subtitle">
          <h4>
            {appDetails.name} {plan} - £
            {price} per month{" "}
            {/* Temporary solution until plan selection implemented */}
          </h4>
          <p>Bill added to existing {appDetails.name} payment</p>
        </div>
        <LoadingButton onClick={submitForm} text={"Create Account"} singleUse={true} />
      </div>
    </div>
  ) : null;
}

export default AccountOnboardingForm;
