/* eslint-disable guard-for-in */
/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

interface OrganisationDetailsModuleProps {
  orgData: any;
  managedServices: any;
}

function OrganisationDetailsModule(props: OrganisationDetailsModuleProps) {
  const [tableRows, setTableRows] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);

  useEffect(() => {
    calculateAppSend();
  }, []);

  const dateNextMonth = new Date(Date.now() + 31 * 24 * 60 * 60 * 1000);

  async function calculateAppSend() {
    const appSpendTableRows: any = [];
    let totalSpend = 0;

    for (const serviceName in props.managedServices) {
      const supportedServicesString: any = await localStorage.getItem("supportedServicesString");
      const supportedServices = await JSON.parse(supportedServicesString);

      const managedService: any = props.managedServices[serviceName];
      const serviceDetails: any = supportedServices[serviceName];

      const accountUsers = Object.keys(managedService.users);
      const accountPlan = managedService.users[accountUsers[0]];
      const userCount = accountUsers.length;
      const price = serviceDetails.plans[managedService.users[accountUsers[0]]];

      if (serviceDetails !== null) {
        appSpendTableRows.push(
          <tr>
            <td>{managedService.name + " " + accountPlan}</td>
            <td>{userCount}</td>
            <td>£{price.toFixed(2)}</td>
            <td>£{(userCount * price).toFixed(2)}</td>
          </tr>,
        );
        totalSpend += userCount * price;
      }
    }
    setTotalSpend(totalSpend);
    setTableRows(appSpendTableRows);
  }

  return (
    <div className="onethird-full-height-module">
      <div className="module-header">
        <div className="title-subtitle">
          <h3 className="panel-title-txt">{props.orgData.name}</h3>
          <p>See your organisation's details and spend.</p>
        </div>
      </div>
      <div className="module-component">
        <div className="module-component-header">
          <h4>Organisation Details</h4>
        </div>
        <div>
          <p>Name: {props.orgData.name}</p>
          <p>Email Extension: {props.orgData.emailExtension}</p>
          <p>Head Count: {props.orgData.teammembers.length}</p>
          <p>Admins: {props.orgData.admins.map((admin: string) => admin).join(", ")}</p>
        </div>
      </div>

      <div className="module-component">
        <div className="module-component-header">
          <h4>Spend by App</h4>
        </div>
        <div>
          <table>
            <tr>
              <td style={{ fontWeight: "500" }}>Name</td>
              <td style={{ fontWeight: "500" }}>Users</td>
              <td style={{ fontWeight: "500" }}>Fee</td>
              <td style={{ fontWeight: "500" }}>Total</td>
            </tr>
            {tableRows}
            <tr>
              <td colSpan={3} style={{ textAlign: "right", paddingTop: "8px", fontWeight: "500" }}>
                Total Monthly
              </td>
              <td style={{ paddingTop: "8px", fontWeight: "500" }}>£{totalSpend.toFixed(2)}</td>
            </tr>
          </table>
        </div>
        <p>Billing is handled by the service providers. Data includes managed apps only.</p>
      </div>

      <div className="module-component">
        <div className="module-component-header">
          <h4>Billing</h4>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="button pill secondary right">
                <h5> Edit Details</h5>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="modal-overlay" />
              <Dialog.Content className="modal-cont">
                <div className="modal-header">
                  <div className="title-subtitle-cont">
                    <h3 style={{ marginBottom: 4 }}>Edit Billing Details</h3>
                    <p>Billing details cannot be edited on this demo account.</p>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <p>
            Your next Attic bill is due on{" "}
            {/* {new Date(props.orgData.nextBill).toLocaleDateString("en-GB", { day: "numeric", month: "long" })} */}
            {dateNextMonth.toLocaleDateString("en-GB", { day: "numeric", month: "long" })}. Payment is set to{" "}
            {props.orgData.billingDetails.cardName}'s {props.orgData.billingDetails.cardType} ending in{" "}
            {props.orgData.billingDetails.lastDigits}.
          </p>
        </div>
      </div>

      <div className="module-component">
        <div className="module-component-header">
          <h4>Delete Attic</h4>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <button className="button pill secondary right">
                <h5>Delete</h5>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="modal-overlay" />
              <Dialog.Content className="modal-cont">
                <div className="modal-header">
                  <div className="title-subtitle-cont">
                    <h3 style={{ marginBottom: 4 }}>Delete {props.orgData.name}'s Attic</h3>
                    <p>Demo organisation cannot be deleted.</p>
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div>
          <p>
            By deleting your Attic, all of you and your subordinate’s logins will be deleted. You can opt to avoid
            deleting managed accounts.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrganisationDetailsModule;
