import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import "../../App.css";
import LoadingElement from "../atoms/loadingElement";
import { SERVER_URL } from "../../App";
import { IoTime } from "react-icons/io5";
import ChangeSharedAccountAccess from "../atoms/changeSharedAccountAccess";

interface SharedAppCardandModalProps {
  user: any;
  updateUserData: () => void;
  name: string;
  lastUsed: string;
}

function SharedAppCardandModal(props: SharedAppCardandModalProps) {
  const [accountDetails, setAccountDetails] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(true);
  const [sharingDetails, setSharingDetails] = useState({
    accountUsers: { admin: [""], managed: [""] },
    accountHostname: "",
  });
  const [showChannelEdit, setShowChannelEdit] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   getSharingDetails();
  // }, []);

  // Get the details of a shared account
  async function getSharingDetails() {
    const url = SERVER_URL + "/dashboard/getAccountSharingDetails";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        accountName: props.name,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();
      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 500) {
        navigate("/login?trigger=getAccountSharingDetails&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        return Promise.reject(new Error(data.msg));
      } else {
        setSharingDetails(data["data"]);
        console.log(data["data"]);
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=getAccountSharingDetails");
    }
  }

  function getRelativeTime(timestamp: any) {
    if (timestamp === "Never") {
      return "Never used";
    }
    const millisecondsAgo = Date.now() - timestamp;
    const secondsAgo = Math.floor(millisecondsAgo / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    const weeksAgo = Math.floor(daysAgo / 7);
    const monthsAgo = Math.floor(daysAgo / 30);

    if (hoursAgo < 24) {
      return `Used Today`;
    } else if (daysAgo < 7) {
      switch (daysAgo) {
        case 1:
          return "Used Yesterday";
        default:
          return `Used ${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
      }
    } else if (weeksAgo < 4) {
      return `Used ${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
    } else {
      return `Used ${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
    }
  }

  let accoundDetailsLoading = false;
  let retryCount = 0;

  async function getAccountDetails() {
    if (!accoundDetailsLoading) {
      accoundDetailsLoading = true;
      setAccountDetails(["Loading"]);
      const url = SERVER_URL + "/dashboard/CollectSharedAccountDetails";
      const token = localStorage.getItem("token");

      // Set server timeout
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 20000);
      });

      // Get data
      const responsePromise = fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          user: props.user.email,
          accountHostname: sharingDetails.accountHostname,
        }),
      });

      try {
        // Sets race between timeout and responses
        const response: any = await Promise.race([responsePromise, timeoutPromise]);

        // Decode response data
        const data = await response.json();

        accoundDetailsLoading = false;
        // If the error is due to auth, encrpytion keys, or a server crash
        if (response.status === 403 || response.status === 500 || (response.status === 410 && retryCount > 3)) {
          navigate("/login?trigger=getIndAppDetails&error=true&responseStatus=" + response.status);
          localStorage.clear();
          return Promise.reject(new Error(data.msg));
        } else if (response.status === 410) {
          //keys not found - retry
          await new Promise((resolve) => setTimeout(resolve, 2000));
          retryCount++;
          getAccountDetails();
          return;
        } else if (!data["success"]) {
          return Promise.reject(new Error(data.msg));
        } else {
          setAccountDetails([data["data"].username, data["data"].password]);
          return Promise.resolve();
        }
      } catch (error) {
        // Redirect to login
        accoundDetailsLoading = false;
        console.log(error);
        navigate("/login?error=true&responseStatus=408&trigger=getIndAppDetails");
      }
    }
  }
  return (
    <Dialog.Root
      onOpenChange={(open) => {
        if (open) {
          getSharingDetails();
        } else {
          setAccountDetails([]);
        }
      }}
    >
      <Dialog.Trigger asChild>
        <div className="app-card clickable" key={props.name}>
          <div className={"card-header"}>
            <div className="app-icon small placeholder">{props.name[0]}</div>
            <h4>{props.name}</h4>
          </div>
          <div className="icon-text-cont">
            <IoTime />
            <p>{getRelativeTime(props.lastUsed)}</p>
          </div>
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay" />
        <Dialog.Content className="modal-cont">
          <div className="modal-header">
            <div className="title-subtitle-cont">
              <h3>{props.name}</h3>
            </div>
          </div>
          <div className="modal-body">
            {sharingDetails.accountUsers.admin[0] === "" ? (
              <div className="modal-loading-element">
                <LoadingElement spinnerSize={36} />
              </div>
            ) : null}

            <div className="module-component">
              <div className="module-component-header">
                <h4>Sharing Details</h4>
              </div>
              <div>
                <p>Admin: {sharingDetails.accountUsers.admin[0]}</p>
                <p>Users: {sharingDetails.accountUsers.managed.join(", ")}</p>
              </div>
            </div>

            <div className="module-component">
              <div className="module-component-header">
                <h4>Login Details</h4>
              </div>

              {accountDetails.length === 0 ? (
                <div onClick={() => getAccountDetails()} className="green-text-cont break clickable">
                  <h4>Click to Reveal</h4>
                </div>
              ) : accountDetails.length === 2 ? (
                <div className="green-text-cont break">
                  <div>
                    <h4>{accountDetails[0]}</h4>
                    <h4>{accountDetails[1]}</h4>
                  </div>
                </div>
              ) : (
                <div className="green-text-cont break">
                  <LoadingElement />
                </div>
              )}
            </div>
            <ChangeSharedAccountAccess
              user={props.user}
              appName={props.name}
              updateUserData={props.updateUserData}
              sharedAppDetails={sharingDetails}
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default SharedAppCardandModal;
