import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import Slack from "../atoms/accountUIs/slack";
import Figma from "../atoms/accountUIs/figma";
import GoogleWorkspace from "../atoms/accountUIs/googleWorkspace";
import "../../App.css";
import LoadingButton from "../atoms/loadingButton";
import LoadingElement from "../atoms/loadingElement";
import { SERVER_URL } from "../../App";
import { IoTime } from "react-icons/io5";
import ChangeAccountAccess from "../atoms/changeAccountAccess";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";

interface AppCardandModalProps {
  user: any;
  updateUserData: () => void;
  name: string;
  lastUsed: string;
  serviceDetail: any;
  appInstance: any;
  orgServiceDetail: any;
}

function AppCardandModal(props: AppCardandModalProps) {
  const [accountConfiguration, setAccountConfiguration] = useState({});
  const [accountDetails, setAccountDetails] = useState<Array<string>>([]);
  const [showChannelEdit, setShowChannelEdit] = useState(false);
  const [showChangeDetails, setShowChangeDetails] = useState(false);
  const [plan, setPlan] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [price, setPrice] = useState();
  const navigate = useNavigate();

  // Object containing the UIs of all managed apps
  const accounts: any = {
    Slack: (
      <Slack
        userEmail={props.user.email}
        channelEdit={showChannelEdit}
        setAccountConfiguration={setAccountConfiguration}
        setLoaded={setIsLoaded}
      />
    ),
    Figma: (
      <Figma
        userEmail={props.user.email}
        teamEdit={showChannelEdit}
        setAccountConfiguration={setAccountConfiguration}
        setLoaded={setIsLoaded}
      />
    ),
    "Google Workspace": (
      <GoogleWorkspace
        userEmail={props.user.email}
        userDetails={{ firstName: props.user.firstName, surname: props.user.surname, email: props.user.email }}
        setAccountConfiguration={setAccountConfiguration}
        setLoaded={setIsLoaded}
      />
    ),
  };

  useEffect(() => {
    if (props.orgServiceDetail !== undefined) {
      const appUsers = Object.keys(props.orgServiceDetail.users);
      props.orgServiceDetail.users[appUsers[0]];
      setPlan(props.orgServiceDetail.users[appUsers[0]]);
    }
  }, [props.orgServiceDetail]);

  useEffect(() => {
    if (props.orgServiceDetail !== undefined) {
      setPrice(props.serviceDetail.plans[plan]);
    }
  }, [plan]);

  function getRelativeTime(timestamp: any) {
    if (timestamp === "Never") {
      return "Never used";
    }
    const millisecondsAgo = Date.now() - timestamp;
    const secondsAgo = Math.floor(millisecondsAgo / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    const weeksAgo = Math.floor(daysAgo / 7);
    const monthsAgo = Math.floor(daysAgo / 30);

    if (hoursAgo < 24) {
      return `Used Today`;
    } else if (daysAgo < 7) {
      switch (daysAgo) {
        case 1:
          return "Used Yesterday";
        default:
          return `Used ${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
      }
    } else if (weeksAgo < 4) {
      return `Used ${weeksAgo} week${weeksAgo === 1 ? "" : "s"} ago`;
    } else {
      return `Used ${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
    }
  }

  let accoundDetailsLoading = false;

  let retryCount = 0;

  async function getAccountDetails() {
    if (!accoundDetailsLoading) {
      accoundDetailsLoading = true;
      setAccountDetails(["Loading"]);
      const url = SERVER_URL + "/dashboard/CollectIndividualAccountDetails";
      const token = localStorage.getItem("token");

      // Set server timeout
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 20000);
      });

      // Get data
      const responsePromise = fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          user: props.user.email,
          accountSlug: props.appInstance.slug,
        }),
      });

      try {
        // Sets race between timeout and responses
        const response: any = await Promise.race([responsePromise, timeoutPromise]);

        // Decode response data
        const data = await response.json();
        accoundDetailsLoading = false;
        // If the error is due to auth, encrpytion keys, or a server crash
        if (response.status === 403 || response.status === 500 || (response.status === 410 && retryCount > 3)) {
          navigate("/login?trigger=getIndAppDetails&error=true&responseStatus=" + response.status);
          localStorage.clear();
          return Promise.reject(new Error(data.msg));
        } else if (response.status === 410) {
          //keys not found - retry
          await new Promise((resolve) => setTimeout(resolve, 2000));
          retryCount++;
          getAccountDetails();
          return;
        } else if (!data["success"]) {
          return Promise.reject(new Error(data.msg));
        } else {
          setAccountDetails([data["data"].username, data["data"].password]);
          return Promise.resolve();
        }
      } catch (error) {
        accoundDetailsLoading = false;
        // Redirect to login
        console.log(error);
        // navigate("/login?error=true&responseStatus=408&trigger=getIndAppDetails");
      }
    }
  }

  async function updateAccountConfig() {
    console.log(accountConfiguration);
    const url = SERVER_URL + "/dashboard/UpdateManagedAccountConfig";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        accountSlug: props.serviceDetail.slug,
        updatedAccountConfiguration: accountConfiguration,
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();
      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=updatedAccountConfiguration&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        console.log(data);
        return Promise.reject(new Error(data.msg));
      } else {
        setShowChannelEdit(false);
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=updatedAccountConfiguration");
    }
  }

  async function updateAccountDetails() {
    const url = SERVER_URL + "/dashboard/EditIndAccountDetails";
    const token = localStorage.getItem("token");

    // Set server timeout
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error("Request timed out"));
      }, 20000);
    });

    // Get data
    const responsePromise = fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user: props.user.email,
        accountSlug: props.serviceDetail.slug,
        newUsername: accountDetails[0],
        newPassword: accountDetails[1],
      }),
    });

    try {
      // Sets race between timeout and responses
      const response: any = await Promise.race([responsePromise, timeoutPromise]);

      // Decode response data
      const data = await response.json();
      // If the error is due to auth, encrpytion keys, or a server crash
      if (response.status === 403 || response.status === 410 || response.status === 500) {
        navigate("/login?trigger=updateIndAccountDetails&error=true&responseStatus=" + response.status);
        localStorage.clear();
        return Promise.reject(new Error(data.msg));
      } else if (!data["success"]) {
        console.log(data);
        return Promise.reject(new Error(data.msg));
      } else {
        setShowChangeDetails(false);
        return Promise.resolve();
      }
    } catch (error) {
      // Redirect to login
      console.log(error);
      navigate("/login?error=true&responseStatus=408&trigger=updateIndAccountDetails");
    }
  }

  if (props.name in accounts && props.serviceDetail !== undefined) {
    return (
      <Dialog.Root
        onOpenChange={(open) => {
          if (!open) {
            setAccountDetails([]);
            setIsLoaded(false);
          }
        }}
      >
        <Dialog.Trigger asChild>
          <div className="app-card clickable" key={props.name}>
            <div className={"card-header"}>
              <img className="app-icon small" src={props.serviceDetail.icon192}></img>
              <h4>{props.name}</h4>
            </div>
            <div className="icon-text-cont">
              <IoTime />
              <p>{getRelativeTime(props.lastUsed)}</p>
            </div>
          </div>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="modal-overlay" />
          <Dialog.Content className="modal-cont">
            <div className="modal-header">
              <img className="app-icon" src={props.serviceDetail.icon192}></img>
              <div className="modal-title-subtitle-cont">
                <h3>
                  {props.name} {plan}
                </h3>
                <h4> Managed by Attic | £{price} Billed Monthly</h4>
              </div>
            </div>
            <div className="modal-body">
              {!isLoaded ? (
                <div className="modal-loading-element">
                  <LoadingElement spinnerSize={36} />
                </div>
              ) : null}
              <div className="module-component">
                <div className="module-component-header">
                  <h4>Account Configuration</h4>
                  {showChannelEdit ? (
                    <div className="button-cont row">
                      <button className="button pill secondary" onClick={() => setShowChannelEdit(!showChannelEdit)}>
                        <h5>Close</h5>
                      </button>
                    </div>
                  ) : (
                    <div className="button-cont row">
                      <button className="button pill secondary" onClick={() => setShowChannelEdit(!showChannelEdit)}>
                        <h5>Edit</h5>
                      </button>
                    </div>
                  )}
                </div>
                {accounts[props.name]}
                <div style={{ marginTop: 8 }} hidden={!showChannelEdit}>
                  <LoadingButton text={"Update"} singleUse={true} onClick={updateAccountConfig} />
                </div>
              </div>
              <div className="module-component">
                <div className="module-component-header">
                  <h4>Login Details</h4>
                  {showChangeDetails ? (
                    <div className="button-cont row">
                      <button
                        className="button pill secondary"
                        onClick={() => {
                          getAccountDetails();
                          setShowChangeDetails(!showChangeDetails);
                        }}
                      >
                        <h5>Close</h5>
                      </button>
                    </div>
                  ) : (
                    <div className="button-cont row">
                      <button
                        className="button pill secondary right"
                        onClick={() => {
                          getAccountDetails();
                          setShowChangeDetails(!showChangeDetails);
                        }}
                      >
                        <h5>Edit</h5>
                      </button>
                    </div>
                  )}
                </div>

                {accountDetails.length === 0 ? (
                  <div onClick={() => getAccountDetails()} className="green-text-cont break clickable">
                    <h4>Click to Reveal</h4>
                  </div>
                ) : accountDetails.length === 2 && showChangeDetails ? (
                  <div hidden={!showChangeDetails}>
                    <form
                      className="form-cont wide"
                      onSubmit={(event) => {
                        event.preventDefault();
                        document.getElementById("inv-change-details")?.click();
                      }}
                    >
                      <input
                        type={"text"}
                        placeholder="Username"
                        onChange={(event) => setAccountDetails([event.target.value, accountDetails[1]])}
                        value={accountDetails[0]}
                      />
                      <div style={{ position: "relative" }}>
                        <input
                          type={passwordHidden ? "password" : "text"}
                          placeholder="Password"
                          onChange={(event) => setAccountDetails([accountDetails[0], event.target.value])}
                          value={accountDetails[1]}
                        />
                        {passwordHidden ? (
                          <HiOutlineEye onClick={() => setPasswordHidden(false)} className="password-icon" size={20} />
                        ) : (
                          <HiOutlineEyeSlash
                            onClick={() => setPasswordHidden(true)}
                            className="password-icon"
                            size={20}
                          />
                        )}
                      </div>
                      <LoadingButton
                        id="inv-change-details"
                        text={"Update"}
                        singleUse={true}
                        onClick={updateAccountDetails}
                      />
                    </form>
                  </div>
                ) : accountDetails.length === 2 && !showChangeDetails ? (
                  <div className="green-text-cont break">
                    <div>
                      <h4>{accountDetails[0]}</h4>
                      <h4>{accountDetails[1]}</h4>
                    </div>
                  </div>
                ) : (
                  <div className="green-text-cont break">
                    <LoadingElement />
                  </div>
                )}
              </div>

              <ChangeAccountAccess user={props.user} appName={props.name} updateUserData={props.updateUserData} />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  } else {
    return (
      <Dialog.Root
        onOpenChange={(open) => {
          if (!open) {
            setAccountDetails([]);
          }
        }}
      >
        <Dialog.Trigger asChild>
          <div className="app-card clickable" key={props.name}>
            <div className={"card-header"}>
              <div className="app-icon small placeholder">{props.name[0]}</div>
              <h4>{props.name}</h4>
            </div>
            <div className="icon-text-cont">
              <IoTime />
              <p>{getRelativeTime(props.lastUsed)}</p>
            </div>
          </div>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="modal-overlay" />
          <Dialog.Content className="modal-cont">
            <div className="modal-header">
              <div className="title-subtitle-cont">
                <h3>{props.name}</h3>
              </div>
            </div>
            <div className="modal-body">
              <div className="module-component">
                <div className="module-component-header">
                  <h4>Login Details</h4>
                  {showChangeDetails ? (
                    <div className="button-cont row">
                      <button
                        className="button pill secondary"
                        onClick={() => {
                          getAccountDetails();
                          setShowChangeDetails(!showChangeDetails);
                        }}
                      >
                        <h5>Close</h5>
                      </button>
                    </div>
                  ) : (
                    <div className="button-cont row">
                      <button
                        className="button pill secondary right"
                        onClick={() => {
                          getAccountDetails();
                          setShowChangeDetails(!showChangeDetails);
                        }}
                      >
                        <h5>Edit</h5>
                      </button>
                    </div>
                  )}
                </div>

                {accountDetails.length === 0 ? (
                  <div onClick={() => getAccountDetails()} className="green-text-cont break clickable">
                    <h4>Click to Reveal</h4>
                  </div>
                ) : accountDetails.length === 2 && showChangeDetails ? (
                  <div hidden={!showChangeDetails}>
                    <div className="form-cont wide">
                      <input
                        type={"text"}
                        placeholder="Username"
                        onChange={(event) => setAccountDetails([event.target.value, accountDetails[1]])}
                        onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
                        value={accountDetails[0]}
                      />
                      <input
                        type={"password"}
                        placeholder="Password"
                        onChange={(event) => setAccountDetails([accountDetails[0], event.target.value])}
                        onKeyDown={(event) => event.key === "Enter" && document.getElementById("submit-btn")?.click()}
                        value={accountDetails[1]}
                      />
                      <LoadingButton text={"Update"} singleUse={true} onClick={updateAccountDetails} />
                    </div>
                  </div>
                ) : accountDetails.length === 2 && !showChangeDetails ? (
                  <div className="green-text-cont break">
                    <div>
                      <h4>{accountDetails[0]}</h4>
                      <h4>{accountDetails[1]}</h4>
                    </div>
                  </div>
                ) : (
                  <div className="green-text-cont break">
                    <LoadingElement />
                  </div>
                )}
              </div>
            </div>

            <ChangeAccountAccess user={props.user} appName={props.name} updateUserData={props.updateUserData} />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }
}

export default AppCardandModal;
