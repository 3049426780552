/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { SERVER_URL } from "../../App";
import LoadingButton from "../atoms/loadingButton";

function CompanyRegistrationForm() {
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [region, setReigon] = useState("");
  const [errorMessage, setErrormessage] = useState("");
  const [hidden, setHidden] = useState(false);

  function nextStep() {
    if (companyName === "") {
      setErrormessage("No fields can be blank.");
    } else {
      setErrormessage("");
      setHidden(true);
    }
  }

  function submitForm() {
    const url = SERVER_URL + "/atticaccounts/RegisterCompany";
    // All fields must have data
    if (email === "" || firstName === "" || surname === "" || companyName === "") {
      setErrormessage("No fields can be blank");
      return Promise.reject(new Error("No fields can be blank."));
    }

    // Email validation
    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    if (!emailFormat) {
      setErrormessage("Not a valid email address.");
      return Promise.reject(new Error("Not a valid email address."));
    }

    return fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        companyName: companyName,
        firstName: firstName,
        surname: surname,
        region: region,
      }),
    })
      .then((res) => {
        const data: any = res.json();
        return data;
      })
      .then((data) => {
        if (data["success"]) {
          setHidden(true);
          return Promise.resolve();
        } else {
          console.log(data);
          setErrormessage(data["msg"]);
          return Promise.reject(data["msg"]);
        }
      });
  }

  return (
    <div>
      <div hidden={hidden}>
        <div className="form-cont wide">
          <h2>Let's get started</h2>
          <input
            type="text"
            autoComplete="off"
            autoCapitalize="off"
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("continue-btn-1")?.click()}
            style={{ width: "450px" }}
            placeholder="Company Name"
            onChange={(event) => setCompanyName(event.target.value)}
            value={companyName}
          />
          <select value={region} onChange={(event) => setReigon(event.target.value)}>
            <option selected value="grapefruit">
              United Kingdom
            </option>
            <option value="lime">United States</option>
            <option value="coconut">Australia</option>
            <option value="coconut">Canada</option>
            <option value="coconut">Ireland</option>
            <option value="coconut">New Zealand</option>
            <option value="coconut">South Africa</option>
            <option value="mango">Other</option>
          </select>

          <button
            id="continue-btn-1"
            className="submit-button"
            onClick={() => {
              nextStep();
            }}
          >
            <h4 className="button-txt">Continue</h4>
          </button>
        </div>
      </div>
      <div hidden={!hidden}>
        <div className="form-cont wide">
          <h2>Create your account</h2>
          <div className="half-input-cont">
            <input
              className="halfinput"
              type="text"
              autoComplete="off"
              placeholder="First Name"
              onChange={(event) => setFirstName(event.target.value)}
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("continue-btn")?.click()}
              value={firstName}
            />
            <input
              className="halfinput"
              type="text"
              autoComplete="off"
              onKeyDown={(event) => event.key === "Enter" && document.getElementById("continue-btn")?.click()}
              placeholder="Surname"
              onChange={(event) => setSurname(event.target.value)}
              value={surname}
            />
          </div>
          <input
            type="text"
            autoCapitalize="off"
            onKeyDown={(event) => event.key === "Enter" && document.getElementById("continue-btn")?.click()}
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <LoadingButton id="continue-btn" text="Continue to Payment" onClick={submitForm} />
        </div>

        <div className="form-error-cont" style={{ padding: 0 }}>
          <p className="error-txt">{errorMessage}</p>
        </div>
      </div>
    </div>
  );
}

export default CompanyRegistrationForm;
