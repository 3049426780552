import React, {useState, useEffect} from "react";
import Navbar from "../../components/atoms/navbar";
import ResetPasswordForm from "../../components/molecules/passwords/resetPasswordForm";
import {useSearchParams} from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

function RequestPasswordReset() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState(true );

  const token = searchParams.get("resetToken");

  function submitPassword() {
    setShowPasswordInput(true);
    setPasswordCheck(false);
    setTimeout(() => {
      setPasswordCheck(true);
    }, 4000);
    setTimeout(() => {
      setPasswordConfirm(false);
    }, 4000);
  }

  return (
    <div className="page-container">
      <Navbar authedOnly={false}/>
      <div hidden={showPasswordInput}>
        <div className="full-page-element row" hidden={true}>
          <div className="half-column">
            <div className="center-page-element content-container">
              <h2>Let's make your password super secure.</h2>
              <h2 className="title-txt">F*aint.Mix_ture.Garl%ic</h2>
              <h4>Tip: A good password needs to be long, varied, and random. Try three
                random words, with symbols in the middle of each.</h4>
            </div>
          </div>
          <div className="half-column">
            <div className="center-page-element">
              <ResetPasswordForm
                resetToken={token}
                onSubmit={submitPassword}/>
            </div>
          </div>
        </div>
      </div>

      <div hidden={passwordCheck}>
        <div className="center-page-element full-page-message">
          <h2>Checking to see if your password is secure...</h2>

          <MoonLoader
            color="#20513B"
            cssOverride={{}}
            loading
            size={50}
            speedMultiplier={0.75}
          />
        </div>
      </div>

      <div hidden={passwordConfirm}>
        <div className="center-page-element full-page-message">
          <h2>You nailed it.</h2>
          <p>Your password is secure. Your account has been updated, and you
            are now able to log in again. </p>
        </div>
      </div>
    </div>
  );
}

export default RequestPasswordReset;
